<template>
  <div
    :class="{
      'workWithUs-container modal-container': true,
      active: isModalActive,
    }"
  >
    <div class="workWithUs-wrapper">
      <h2>
        <a href="#" @click.prevent="closeModal"
          ><img class="closesign" src="@/assets/close_icon_new_black.png" alt="" /></a>
      </h2>
      <div class="workWithUs-center">
        <div class="step1" v-if="currentPack === null">
          <div class="top-block">
            <img :src="getImgUrl('footer-logo.png')" id="footer-logo" alt="" />
            <div class="contacts">
              <ul>
                <li>
                  <p>{{ translation.workWithUs.address.row1 }}</p>
                </li>
                <li>
                  <p>{{ translation.workWithUs.address.row2 }}</p>
									<a href="https://www.google.com/maps/place/DQBD+GmbH/@48.8064467,9.5290822,15z/data=!4m6!3m5!1s0x4799bb060a2a9371:0xaaaef6a9f55fec5e!8m2!3d48.8064467!4d9.5290822!16s%2Fg%2F11tw_0t3kj?entry=ttu">
										<svg style="width:21px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 15.6 5.4 37 16.6 63.4c10.9 25.9 26.2 54 43.6 82.1c34.1 55.3 74.4 108.2 99.9 140c25.4-31.8 65.8-84.7 99.9-140c17.3-28.1 32.7-56.3 43.6-82.1C346.6 229 352 207.6 352 192zm32 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"/></svg>
									</a>
                </li>
                <li>
                  <p>{{ translation.workWithUs.address.row3 }}</p>
									<a href="tel:+4971819376660">
										<svg style="width:23px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M136.2 275.2c16.4-7 35.4-2.4 46.7 11.4l33.2 40.6c46-26.7 84.4-65.1 111.1-111.1L286.7 183c-13.8-11.3-18.5-30.3-11.4-46.7l48-112C330.8 6.7 349.7-3.1 368.4 .9l112 24C498.8 28.8 512 45.1 512 64v0c0 236.7-183.5 430.5-416 446.9c-4.5 .3-9.1 .6-13.7 .8c0 0 0 0 0 0c0 0-.1 0-.1 0c-6.1 .2-12.1 .4-18.3 .4l0 0c-18.9 0-35.2-13.2-39.1-31.6l-24-112c-4-18.7 5.8-37.6 23.4-45.1l112-48zM64.3 480C293.9 479.8 480 293.7 480 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0-3.8-2.6-7-6.3-7.8l-112-24c-3.7-.8-7.5 1.2-9 4.7l-48 112c-1.4 3.3-.5 7.1 2.3 9.3l40.6 33.2c12.1 9.9 15.3 27.2 7.4 40.8c-29.5 50.9-71.9 93.3-122.7 122.7c-13.6 7.9-30.9 4.7-40.8-7.4l-33.2-40.6c-2.3-2.8-6.1-3.7-9.3-2.3l-112 48c-3.5 1.5-5.5 5.3-4.7 9l24 112c.8 3.7 4 6.3 7.8 6.3c.1 0 .2 0 .3 0z"/></svg>
									</a>
                </li>
                <li>
                  <p></p>
                </li>
                <li>
                  <a href="mailto:info@dqbd.de"><p>{{ translation.workWithUs.address.row5 }}</p></a>
									<a href="mailto:<?php the_field('email', $general_blocks_pageID); ?>">
										<svg style="width:23px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
									</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="packs-container">
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[0].title }}</h3>
                <a
                  href="#"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('light');
                    }
                  "
                  >{{ translation.workWithUs.packs[0].button }}</a
                >
              </div>
            </div>
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[1].title }}</h3>
                <a
                  href="#"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('medium');
                    }
                  "
                  >{{ translation.workWithUs.packs[1].button }}</a
                >
              </div>
            </div>
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[2].title }}</h3>
                <a
                  href="#"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('premium');
                    }
                  "
                  >{{ translation.workWithUs.packs[2].button }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="step2" v-if="currentPack !== null">
          <div class="top-block" v-if="currentPack !== 'hidden'">
            <div
              :class="{ item: true, active: currentPack === 'light' }"
              @click="
                (e) => {
                  changeCurrentPack('light');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[0] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
            <div
              :class="{ item: true, active: currentPack === 'medium' }"
              @click="
                (e) => {
                  changeCurrentPack('medium');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[1] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
            <div
              :class="{ item: true, active: currentPack === 'premium' }"
              @click="
                (e) => {
                  changeCurrentPack('premium');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[2] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
          </div>
          <form action="POST" enctype="multipart/form-data" >
            <div>
              <transition name="fade">
                <div class="input-wrapper" v-if="!form.firstStepConfirmed">
                  <label for="nameInput">{{
                    translation.workWithUs.form.input_name.label
                  }}</label>
                  <div class="input-group">
                    <div class="input-container">
                      <input
                        type="text"
                        id="nameInput"
                        name="name"
                        :placeholder="
                          translation.workWithUs.form.input_name.placeholder
                        "
                        v-model="form.name.value"
                        :class="{ error: form.name.isError }"
                      />
                      <a
                        href="#"
                        class="close"
                        v-show="form.name.value.length !== 0"
                        @click.prevent="
                          (e) => {
                            clearInput('name');
                          }
                        "
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                    </div>
                    <div class="input-container">
                      <input
                        type="text"
                        id="phoneEmailInput"
                        name="emailPhone"
                        :placeholder="
                          translation.workWithUs.form.input_email.placeholder
                        "
                        v-model="form.emailPhone.value"
                        :class="{ error: form.emailPhone.isError }"
                      />
                      <a
                        v-show="form.emailPhone.value.length !== 0"
                        href="#"
                        class="close"
                        @click.prevent="
                          (e) => {
                            clearInput('emailPhone');
                          }
                        "
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.firstStepConfirmed"
                  @click="editFirstStep"
                >
                  <h3>{{ form.name.value }}</h3>
                  <h3>{{ form.emailPhone.value }}</h3>
                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                v-if="!form.firstStepConfirmed"
                class="button black-button"
                @click.prevent="checkFirstStep"
                >{{ translation.workWithUs.form.buttons.addDetails }}</a
              >
            </div>
            <div v-if="form.firstStepConfirmed">
              <transition name="fade">
                <div class="input-wrapper" v-if="!form.secondStepConfirmed">
                  <label for="projectInput">{{
                    translation.workWithUs.form.input_project.label
                  }}</label>

                  <div class="input-container">
                    <input
                      type="text"
                      id="projectInput"
                      name="project"
                      :placeholder="
                        translation.workWithUs.form.input_project.placeholder
                      "
                      v-model="form.project.value"
                      :class="{ error: form.project.isError }"
                    />
                    <a
                      href="#"
                      class="close"
                      v-show="form.project.value.length !== 0"
                      @click.prevent="
                        (e) => {
                          clearInput('project');
                        }
                      "
                      ><i class="fa-solid fa-circle-xmark"></i
                    ></a>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.secondStepConfirmed"
                  @click="editSecondStep"
                >
                  <h3>
                    {{
                      form.project.value.length !== 0
                        ? form.project.value
                        : translation.workWithUs.form.infoNotAdded
                    }}
                  </h3>

                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                class="button black-button"
                @click.prevent="checkSecondStep"
                v-if="!form.secondStepConfirmed"
                >{{ translation.workWithUs.form.buttons.addInfo }}</a
              >
            </div>
            <div v-show="form.firstStepConfirmed && form.secondStepConfirmed">
              <transition name="fade">
                <div class="input-wrapper" >
                  <label for="documentsInput">{{
                    translation.workWithUs.form.input_document.label
                  }}</label>
                  <div class="input-group">
                    <div class="input-container">
                      <label>
                        <input
                          type="text"
                          id="fileInput"
                          name="file"
                          :placeholder="
                            translation.workWithUs.form.input_document
                              .placeholder
                          "
                          v-model="form.file.value"
                          disabled
                          :class="{ error: form.file.isError }"
                        />
                        <input
                          type="file"
                          id="fileInputHidden"
                          name="file"
                          @change="onFileChange"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.thridStepConfirmed"
                  @click="editThridStep"
                >
                  <h3>
                    {{
                      form.link.value.length === 0 &&
                      form.file.value.length === 0
                        ? translation.workWithUs.form.materialsNotAdded
                        : translation.workWithUs.form.materialsAdded
                    }}
                  </h3>
                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                class="button black-button"
                @click.prevent="checkThridStep"
                v-if="!form.thridStepConfirmed"
                >{{ translation.workWithUs.form.buttons.addMaterials }}</a
              >
            </div>
            <a
              href="#"
              class="button black-button"
              @click.prevent="sendRequest"
              v-if="
                form.firstStepConfirmed &&
                form.secondStepConfirmed &&
                form.thridStepConfirmed
              "
              >{{ (sendingReqeust ? translation.workWithUs.form.buttons.sending : translation.workWithUs.form.buttons.send) }}</a
            ><span
              class="showThanks"
              v-show="showThanks"
              >{{ translation.workWithUs.form.thanks_for_message }}</span><span class="showThanks" v-show="showError" style="color:#ff0000;">{{ translation.workWithUs.form.error_on_message }}</span>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
export default {
  name: "WorkWithUs",
  props: {
    translation: Object,
  },
  data() {
    return {
      isModalActive: false,
      sendingReqeust: false,
      showThanks: false,
      showError: false,
      form: {
        name: { isError: false, value: "" },
        emailPhone: { isError: false, value: "" },
        project: { isError: false, value: "" },
        link: { isError: false, value: "" },
        file: { isError: false, value: "" },

        firstStepConfirmed: false,
        secondStepConfirmed: false,
        thridStepConfirmed: false,
      },
      currentPack: null,
    };
  },
  mounted() {
    window.resetFooterModal = () => {
      this.resetSection();
    };
    window.animateFooterModal = () => {
      this.animateOnLoad();
    };
    window.showModal = this.showModal;
    window.closeModal = this.closeModal;
  },
  methods: {
    closeModal() {
      this.isModalActive = false;
      this.form = {
        name: { isError: false, value: "" },
        emailPhone: { isError: false, value: "" },
        project: { isError: false, value: "" },
        link: { isError: false, value: "" },
        file: { isError: false, value: "" },

        firstStepConfirmed: false,
        secondStepConfirmed: false,
        thridStepConfirmed: false,
      };
      this.currentPack = null;
    },
    showModal(choice) {
      if (choice) {
        this.currentPack = choice;
      }
      this.isModalActive = true;
      this.animateOnLoad();
    },
    animateOnLoad() {
      gsap.to(".contacts", { duration: 0.7, opacity: 1, x: 0 });

      gsap.to(".packs-container", {
        duration: 0.7,
        opacity: 1,
        y: 0,
      });
    },
    resetSection() {
      this.form = {
        name: { isError: false, value: "" },
        emailPhone: { isError: false, value: "" },
        project: { isError: false, value: "" },
        link: { isError: false, value: "" },
        file: { isError: false, value: "" },

        firstStepConfirmed: false,
        secondStepConfirmed: false,
        thridStepConfirmed: false,
      };
      this.currentPack = null;
    },
    changeCurrentPack(pack) {
      this.currentPack = pack;
      window.rebuildFP();
    },
    onFileChange(e) {
      this.form.file.value = e.target.value;
    },
    sendRequest() {
		if(this.sendingReqeust) return false;
      const name = this.form.name.value;
      const emailPhone = this.form.emailPhone.value;
      const whatisitabout = this.form.project.value;
      const link = this.form.link.value;
      const file = document.getElementById('fileInputHidden').files[0];
	  let chosenPath = this.currentPack;
      if(this.currentPack=='light'){
		  chosenPath = 'Ideen zur Marktreife';
	  }else if(this.currentPack=='medium'){
		chosenPath = 'Gemeinsam meer';
	  }else if(this.currentPack=='premium'){
		chosenPath = 'Meeting';
	  }else chosenPath = 'Jobanfrage';
      this.sendingReqeust = true;
	  
		let formData = new FormData(); 
		formData.append("image", file);
		formData.append('a',name)
		formData.append('b',emailPhone)
		formData.append('c',whatisitabout)
		formData.append('d','')
		formData.append('e',chosenPath)

console.log('formData',formData)
	  axios.post('https://dqbd.de/en/mail/',  formData,
	   {headers: {
       'Content-Type': 'multipart/form-data'
      }})
		 .then((res) => {
			if(res?.data == false){
				
				this.showError=true;
				return 
			}
			this.showError=false;
			this.showThanks=true;
			let _t = this;
			setTimeout(()=> { _t.showThanks = false; this.sendingReqeust = false; }, 2000);
		 })
		 .catch((error) => {
			 this.sendingReqeust = false;
		 }).finally(() => {
			 this.sendingReqeust = false;
		 });
		 
    },
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
    clearInput(name) {
      this.form[name].value = "";
      this.form[name].valid = false;
    },
    checkFirstStep() {
      if (this.form.name.value.length === 0) {
        this.form.name.isError = true;
      } else {
        this.form.name.isError = false;
      }
      if (this.form.emailPhone.value.length === 0) {
        this.form.emailPhone.isError = true;
      } else {
        this.form.emailPhone.isError = false;
      }
      if (this.form.emailPhone.isError || this.form.name.isError) {
        return;
      } else {
        this.form.firstStepConfirmed = true;
      }
    },
    checkSecondStep() {
      this.form.secondStepConfirmed = true;
      window.rebuildFP();
    },
    checkThridStep() {
      this.form.thridStepConfirmed = true;
      window.rebuildFP();
    },
    editFirstStep() {
      this.form.firstStepConfirmed = false;
      window.rebuildFP();
    },
    editSecondStep() {
      this.form.secondStepConfirmed = false;
      window.rebuildFP();
    },
    editThridStep() {
      this.form.thridStepConfirmed = false;
      window.rebuildFP();
    },
  },
};
</script>

<style>    
.showThanks {
  margin-left: 20px;
}
.modal-container {
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 999999;
  background: white;
  height: 100vh;
  width: 100vw;
  transition: all 0.5s;
}
.modal-container.active {
  top: 0;
}
.modal-container .packs-container,.modal-container .packs-container2 {
  margin-top: 0;
}
.modal-container .workWithUs-wrapper {
  overflow: scroll;
}
.modal-container .packs-container .item,.modal-container .packs-container2 .item {
  margin-right: 30px;
}
.modal-container .packs-container .item:last-of-type,.modal-container .packs-container2 .item:last-of-type {
  margin-right: 0;
}
</style>
