<template>
  <div class="workWithUs-container">
    <div class="workWithUs-wrapper">
      <h2>{{ translation.workWithUs.title }}</h2>
      <div class="workWithUs-center">
        <div class="step1" v-if="currentPack === null">
          <div class="top-block">
            <img :src="getImgUrl('footer-logo.png')" id="footer-logo" alt="" />
            <div class="contacts">
              <ul>
                <li>
                  <p>{{ translation.workWithUs.address.row1 }}</p>
                </li>
                <li>
                  <p>{{ translation.workWithUs.address.row2 }}</p>
									<a href="https://www.google.com/maps/place/DQBD+GmbH/@48.8064467,9.5290822,15z/data=!4m6!3m5!1s0x4799bb060a2a9371:0xaaaef6a9f55fec5e!8m2!3d48.8064467!4d9.5290822!16s%2Fg%2F11tw_0t3kj?entry=ttu">
										<svg style="width:21px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 15.6 5.4 37 16.6 63.4c10.9 25.9 26.2 54 43.6 82.1c34.1 55.3 74.4 108.2 99.9 140c25.4-31.8 65.8-84.7 99.9-140c17.3-28.1 32.7-56.3 43.6-82.1C346.6 229 352 207.6 352 192zm32 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"/></svg>
									</a>
                </li>
                <li>
                  <p>{{ translation.workWithUs.address.row3 }}</p>
									<a href="tel:+4971819376660">
										<svg style="width:23px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M136.2 275.2c16.4-7 35.4-2.4 46.7 11.4l33.2 40.6c46-26.7 84.4-65.1 111.1-111.1L286.7 183c-13.8-11.3-18.5-30.3-11.4-46.7l48-112C330.8 6.7 349.7-3.1 368.4 .9l112 24C498.8 28.8 512 45.1 512 64v0c0 236.7-183.5 430.5-416 446.9c-4.5 .3-9.1 .6-13.7 .8c0 0 0 0 0 0c0 0-.1 0-.1 0c-6.1 .2-12.1 .4-18.3 .4l0 0c-18.9 0-35.2-13.2-39.1-31.6l-24-112c-4-18.7 5.8-37.6 23.4-45.1l112-48zM64.3 480C293.9 479.8 480 293.7 480 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0c0-3.8-2.6-7-6.3-7.8l-112-24c-3.7-.8-7.5 1.2-9 4.7l-48 112c-1.4 3.3-.5 7.1 2.3 9.3l40.6 33.2c12.1 9.9 15.3 27.2 7.4 40.8c-29.5 50.9-71.9 93.3-122.7 122.7c-13.6 7.9-30.9 4.7-40.8-7.4l-33.2-40.6c-2.3-2.8-6.1-3.7-9.3-2.3l-112 48c-3.5 1.5-5.5 5.3-4.7 9l24 112c.8 3.7 4 6.3 7.8 6.3c.1 0 .2 0 .3 0z"/></svg>
									</a>
                </li>
                <li>
                  <p></p>
                </li>
                <li>
                  <a href="mailto:info@dqbd.de"><p>{{ translation.workWithUs.address.row5 }}</p></a>
									<a href="mailto:<?php the_field('email', $general_blocks_pageID); ?>">
										<svg style="width:23px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
									</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="packs-container">
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[0].title }}</h3>
                <a
                  href="#"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('light');
                    }
                  "
                  >{{ translation.workWithUs.packs[0].button }}</a
                >
              </div>
            </div>
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[1].title }}</h3>
                <a
                  href="#"
                  style="    margin-left: 0;"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('medium');
                    }
                  "
                  >{{ translation.workWithUs.packs[1].button }}</a
                >
              </div>
            </div>
            <div class="item">
              <div class="item-wrapper">
                <h3>{{ translation.workWithUs.packs[2].title }}</h3>
                <a
                  href="#"
                  class="button black-button"
                  @click.prevent="
                    (e) => {
                      changeCurrentPack('premium');
                    }
                  "
                  >{{ translation.workWithUs.packs[2].button }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="step2" v-if="currentPack !== null">
          <div class="top-block" v-if="currentPack !== 'hidden'">
            <div
              :class="{ item: true, active: currentPack === 'light' }"
              @click="
                (e) => {
                  changeCurrentPack('light');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[0] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
            <div
              :class="{ item: true, active: currentPack === 'medium' }"
              @click="
                (e) => {
                  changeCurrentPack('medium');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[1] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
            <div
              :class="{ item: true, active: currentPack === 'premium' }"
              @click="
                (e) => {
                  changeCurrentPack('premium');
                }
              "
            >
              <h3>{{ translation.workWithUs.form.packs[2] }}</h3>
              <i class="fa-solid fa-circle-check"></i>
            </div>
          </div>
          <form action="POST">
            <div>
              <transition name="fade">
                <div class="input-wrapper" v-if="!form.firstStepConfirmed">
                  <label for="nameInput">{{
                    translation.workWithUs.form.input_name.label
                  }}</label>
                  <div class="input-group">
                    <div class="input-container">
                      <input
                        type="text"
                        id="nameInput"
                        name="name"
                        :placeholder="
                          translation.workWithUs.form.input_name.placeholder
                        "
                        v-model="form.name.value"
                        :class="{ error: form.name.isError }"
                      />
                      <a
                        href="#"
                        class="close"
                        v-show="form.name.value.length !== 0"
                        @click.prevent="
                          (e) => {
                            clearInput('name');
                          }
                        "
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                    </div>
                    <div class="input-container">
                      <input
                        type="text"
                        id="phoneEmailInput"
                        name="emailPhone"
                        :placeholder="
                          translation.workWithUs.form.input_email.placeholder
                        "
                        v-model="form.emailPhone.value"
                        :class="{ error: form.emailPhone.isError }"
                      />
                      <a
                        v-show="form.emailPhone.value.length !== 0"
                        href="#"
                        class="close"
                        @click.prevent="
                          (e) => {
                            clearInput('emailPhone');
                          }
                        "
                        ><i class="fa-solid fa-circle-xmark"></i
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.firstStepConfirmed"
                  @click="editFirstStep"
                >
                  <h3>{{ form.name.value }}</h3>
                  <h3>{{ form.emailPhone.value }}</h3>
                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                v-if="!form.firstStepConfirmed"
                class="button black-button"
                @click.prevent="checkFirstStep"
                >{{ translation.workWithUs.form.buttons.addDetails }}</a
              >
            </div>
            <div v-if="form.firstStepConfirmed">
              <transition name="fade">
                <div class="input-wrapper" v-if="!form.secondStepConfirmed">
                  <label for="projectInput">{{
                    translation.workWithUs.form.input_project.label
                  }}</label>

                  <div class="input-container">
                    <input
                      type="text"
                      id="projectInput"
                      name="project"
                      :placeholder="
                        translation.workWithUs.form.input_project.placeholder
                      "
                      v-model="form.project.value"
                      :class="{ error: form.project.isError }"
                    />
                    <a
                      href="#"
                      class="close"
                      v-show="form.project.value.length !== 0"
                      @click.prevent="
                        (e) => {
                          clearInput('project');
                        }
                      "
                      ><i class="fa-solid fa-circle-xmark"></i
                    ></a>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.secondStepConfirmed"
                  @click="editSecondStep"
                >
                  <h3>
                    {{
                      form.project.value.length !== 0
                        ? form.project.value
                        : translation.workWithUs.form.infoNotAdded
                    }}
                  </h3>

                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                class="button black-button"
                @click.prevent="checkSecondStep"
                v-if="!form.secondStepConfirmed"
                >{{ translation.workWithUs.form.buttons.addInfo }}</a
              >
            </div>
            <div v-if="form.firstStepConfirmed && form.secondStepConfirmed">
              <transition name="fade">
                <div class="input-wrapper" v-if="!form.thridStepConfirmed">
                  <label for="documentsInput">{{
                    translation.workWithUs.form.input_document.label
                  }}</label>
                  <div class="input-group">
                    <div class="input-container">
                      <label>
                        <input
                          type="text"
                          id="fileInput"
                          name="file"
                          :placeholder="
                            translation.workWithUs.form.input_document
                              .placeholder
                          "
                          v-model="form.file.value"
                          disabled
                          :class="{ error: form.file.isError }"
                        />
                        <input
                          type="file"
                          id="fileInputHidden"
                          name="file"
                          @change="onFileChange"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="success-block"
                  v-if="form.thridStepConfirmed"
                  @click="editThridStep"
                >
                  <h3>
                    {{
                      form.link.value.length === 0 &&
                      form.file.value.length === 0
                        ? translation.workWithUs.form.materialsNotAdded
                        : translation.workWithUs.form.materialsAdded
                    }}
                  </h3>
                  <i class="fa-solid fa-circle-check"></i>
                </div>
              </transition>
              <a
                href="#"
                class="button black-button"
                @click.prevent="checkThridStep"
                v-if="!form.thridStepConfirmed"
                >{{ translation.workWithUs.form.buttons.addMaterials }}</a
              >
            </div>
            <a
              href="#"
              class="button black-button"
              @click.prevent="sendRequest"
              v-if="
                form.firstStepConfirmed &&
                form.secondStepConfirmed &&
                form.thridStepConfirmed
              "
              >{{ translation.workWithUs.form.buttons.send }}</a
            >
          </form>
        </div>
      </div>
      <div class="footer">
        <ul>
          <li>
            <a href="#" @click.prevent="showModalImprint">{{ translation.first.menu[5] }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "WorkWithUs",
  props: {
    rebuild: Function,
    translation: Object,
  },
  data() {
    return {
      form: {
        name: { isError: false, value: "" },
        emailPhone: { isError: false, value: "" },
        project: { isError: false, value: "" },
        link: { isError: false, value: "" },
        file: { isError: false, value: "" },

        firstStepConfirmed: false,
        secondStepConfirmed: false,
        thridStepConfirmed: false,
      },
      currentPack: null,
    };
  },
  mounted() {
    window.resetFooter = () => {
      this.resetSection();
    };
    window.animateFooter = () => {
      this.animateOnLoad();
    };
  },
  methods: {
    showModalImprint() {
      window.showModalImprint();
    },
    animateOnLoad() {
      gsap.to(".contacts", { duration: 0.7, opacity: 1, x: 0 });

      gsap.to(".packs-container", {
        duration: 0.7,
        opacity: 1,
        y: 0,
      });
    },
    resetSection() {
      this.form = {
        name: { isError: false, value: "" },
        emailPhone: { isError: false, value: "" },
        project: { isError: false, value: "" },
        link: { isError: false, value: "" },
        file: { isError: false, value: "" },

        firstStepConfirmed: false,
        secondStepConfirmed: false,
        thridStepConfirmed: false,
      };
      this.currentPack = null;
    },
    changeCurrentPack(pack) {
      this.currentPack = pack;
      this.rebuild();
    },
    onFileChange(e) {
      this.form.file.value = e.target.value;
    },
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
    clearInput(name) {
      this.form[name].value = "";
      this.form[name].valid = false;
    },
    checkFirstStep() {
      if (this.form.name.value.length === 0) {
        this.form.name.isError = true;
      } else {
        this.form.name.isError = false;
      }
      if (this.form.emailPhone.value.length === 0) {
        this.form.emailPhone.isError = true;
      } else {
        this.form.emailPhone.isError = false;
      }
      if (this.form.emailPhone.isError || this.form.name.isError) {
        return;
      } else {
        this.form.firstStepConfirmed = true;
      }
    },
    checkSecondStep() {
      this.form.secondStepConfirmed = true;
      window.rebuildFP();
    },
    checkThridStep() {
      this.form.thridStepConfirmed = true;
      window.rebuildFP();
    },
    editFirstStep() {
      this.form.firstStepConfirmed = false;
      window.rebuildFP();
    },
    editSecondStep() {
      this.form.secondStepConfirmed = false;
      window.rebuildFP();
    },
    editThridStep() {
      this.form.thridStepConfirmed = false;
      window.rebuildFP();
    },
    sendRequest() {
      const name = this.form.name.value;
      const emailPhone = this.form.emailPhone.value;
      const project = this.form.project.value;
      const link = this.form.link.value;
      const file = this.form.file.value;
      console.log("sendRequest", name, emailPhone, project, link, file);
    },
  },
};
</script>

<style>
.footer ul li{
	margin-left:auto;
	margin-right:auto;
}
#fileInputHidden {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.step2 {
  width: 100%;
  max-width: 903px;
}
.step2,
.step1 {
  animation: fade ease 1s;
}
.step2 .success-block {
  border: 1px solid black;
  padding: 34px;
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.step2 .success-block i {
  font-size: 22px;
}
.step2 .success-block h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
}
.step2 .success-block h3:first-of-type {
  margin-right: 20px;
}
.step2 .success-block h3:last-of-type {
  flex: 1;
}
.step2 .input-container .close {
  position: absolute;
  top: 10px;
  font-size: 20px;
  right: 0px;
}
.step2 .input-container {
  position: relative;
}
.step2 .button.black-button {
  display: inline-block;
  background-color: #000;
  color: #fff;
  margin-top: 36px;
}
.step2 label {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  margin-top: 35px;
  margin-bottom: 36px;
  display: block;
}
.step2 input::placeholder {
  color: #000000;
  opacity: 0.3;
  font-size: 22px;
  line-height: 36px;
}
.step2 .input-group {
  display: flex;
}
.step2 .input-group .input-container label {
  margin: 0;
}
.step2 .input-group .input-container {
  margin-right: 80px;
  width: 100%;
}
.step2 .input-group .input-container input {
  width: calc(100% - 30px);
}
.step2 .input-group .input-container:last-of-type {
  margin-right: 0;
}
.step2 input.error {
  border-bottom: 2px red solid;
}
.step2 input {
  font-size: 26px;
  line-height: 36px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  width: calc(100% - 30px);
  outline: none;
  padding-right: 30px;
  background: transparent;
}
.step2 .top-block {
  display: flex;

  justify-content: space-between;
}
.step2 .top-block .item.active {
  color: black;
  border-color: black;
}
.step2 .top-block .item {
  display: flex;
  align-items: center;
  padding: 36px;
  border: 1px solid #d9d9d9;
  color: #d9d9d9;
  width: 277px;
  justify-content: space-between;
  margin-right: 36px;
  border-radius: 2.00229px;
  transition: all 0.3s;
  cursor: pointer;
}
.step2 .top-block .item:last-of-type {
  margin-right: 0;
}
.step2 .top-block .item i {
  font-size: 19.3555px;
}
.step2 .top-block .item h3 {
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
}
.packs-container {
  opacity: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(200px);
}
.modal-container .workWithUs-wrapper .workWithUs-center .packs-container,.packs-container { 
  margin-top: 90px; 
}
.packs-container .item .item-wrapper {
  padding: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.packs-container .item h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 20px;
  transition: all 0.3s;
}
.packs-container .item span {
  font-size: 19.3555px;
  line-height: 23px;
  letter-spacing: 0.05em;

  color: #000;
  display: block;
  opacity: 0.2;
  margin-bottom: 20px;
}
.packs-container .item .button.black-button {
  background-color: #000;
  color: #fff;
}
.packs-container .item .button {
  margin-top: 24px;
  display: inline-block;
  font-size: 20px;
  line-height: 23px;
  padding: 11px 22px;
  transition: all 0.3s;
}
.packs-container .item p {
  font-size: 13.3486px;
  line-height: 19px;
  margin: 0;
  transition: all 0.3s;
  color: #000000;
}
.packs-container .item:hover {
  background: black;
}
.packs-container .item:hover h3 {
  color: white;
}
.packs-container .item:hover p {
  color: white;
}
.packs-container .item:hover span {
  color: white;
}
.packs-container .item:hover .button {
  background-color: #fff;
  color: #000;
}
.packs-container .item {
  max-width: 270px;
  max-height: 270px;
  display: flex;
  border: 1px solid black;
  margin-right: 28px;
}
.packs-container .item:last-of-type {
  margin-right: 0;
}
.workWithUs-wrapper {
  width: 100%;
  padding: 89px 149px 65px 10vw;
  display: flex;
  flex-direction: column;
}
.workWithUs-container {
  width: 100%;
  display: flex;
}
.workWithUs-center {
  flex: 1;
  display: flex;
  padding-bottom: 128px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
.workWithUs-wrapper h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  margin: 0;
}
.workWithUs-wrapper ul li a {
  font-size: 22px;
  line-height: 27px;
  color: #2d2d2d;
}
.workWithUs-wrapper .footer {
  text-align: center;
}
.workWithUs-wrapper .footer ul {
  margin: 0 auto;
  max-width: 1177px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contacts ul {
  display: flex;
  margin: 0;
  flex-direction: column;
}

.contacts p {
  font-size: 26px;
  line-height: 42px;
  margin: 0;
}
.step1 .top-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.step1 .top-block .contacts {
  margin-left: 36px;
  transform: translateX(200px);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
@media (max-width: 1199px) {
  .workWithUs-wrapper {
    padding: 70px;
    width: calc(100vw - 140px);
  }
}
@media (max-width: 991px) {
  .packs-container .item .item-wrapper {
    padding: 20px;
  }
  .packs-container .item {
    margin-right: 10px;
  }
  .step2 {
    width: 100%;
  }
  .step2 .top-block {
    width: 100%;
    flex-direction: column;
  }
  .step2 .top-block .item {
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 0;
  }
  .workWithUs-wrapper .footer ul {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .workWithUs-wrapper .footer ul {
    justify-content: flex-start;
  }
  .footer ul li {
    margin-left: 0;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .footer ul li {
    margin-left: auto;
    margin-right: auto;
  }

  .step2 .input-group {
    flex-direction: column;
  }
  .step2 .input-group .input-container input {
    margin-bottom: 30px;
  }
  .step1 .top-block {
    flex-direction: column;
    margin-top: 40px;
  }
  .step1 .top-block .contacts {
    margin-left: 0;
    margin-top: 40px;
  }
  .packs-container {
    flex-direction: column;
    align-items: center;
  }
  .packs-container .item {
    margin-bottom: 30px;
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 550px) {
  .contacts p {
    font-size: 22px;
  }
  .workWithUs-wrapper h2 {
    font-size: 30px;
  }
  .workWithUs-wrapper {
    padding: 70px 30px;
    width: calc(100vw - 60px);
  }
  .workWithUs-center {
    padding-bottom: 50px;
  }
  .step2 .top-block .item {
    width: calc(100% - 40px);
  }
}

.contacts ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.contacts {
  flex: 1;
}
.contacts ul li a:last-child {
  margin-left: auto;
}
.contacts ul li svg {
  width: 35px;
  height: auto;
}
.modal-container .step1 .top-block {
  margin-top: 90px;
}
@media (max-width: 768px) {
  .contacts ul li svg {
    width: 20px;
  }
  .modal-container .step1 .top-block {
  margin-top: 70px;
}
}
</style>
