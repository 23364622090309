import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../App.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'App',
    component: App,
    redirect: '/en',
  },
  {
    path: '/en',
    name: 'App_en',
    component: App,
  },
  {
    path: '/de',
    name: 'App_de',
    component: App,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
