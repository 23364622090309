<template>
  <div>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section fp-noscroll">
        <First
          :goDown="goDown"
          :translation="currentTranslation"
          :toggleLanguage="toggleLanguage"
          :language="language"
        />
      </div>
      <div class="section fp-noscroll" id="secondpart">
        <Second :translation="currentTranslation" />
      </div>
      <div class="section"><Thrid :translation="currentTranslation" /></div>
      <div class="section fp-noscroll">
        <Services :translation="currentTranslation" />
      </div>
      <div class="section">
        <Team :translation="currentTranslation" />
      </div>
      <div class="section fp-noscroll">
        <Reviews :translation="currentTranslation" />
      </div>
      <div class="section">
        <WorkWithUs :translation="currentTranslation" :rebuild="rebuild" />
      </div>
      <!-- <div class="section">
        <Posts :translation="currentTranslation" />
      </div> -->
    </full-page>
    <Modal :translation="currentTranslation" />
    <Impressum :translation="currentTranslation" />
    <div :class="{ 'fixed-header': true, shown: isFixedHeaderShown }">
      <a href="#" class="corner-block logo-block">
        <img src="@/assets/Logo.png" alt="" />
      </a>
      <a href="#" class="mobile-menu-toggler" @click.prevent="openMobileMenu"
        ><img src="@/assets/mobile-bar.png" alt=""
      /></a>
      <ul class="menu">
        <li>
          <a :href="language === 'en' ? '/en/projects' : '/de/projekte'">{{ currentTranslation.first.menu[0] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-services' : '/de/unsere-leistungen'">{{ currentTranslation.first.menu[1] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/team' : '/de/team'">{{ currentTranslation.first.menu[2] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/partnerships' : '/de/partner'">{{ currentTranslation.first.menu[3] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-stories' : '/de/neueste-stories'">{{ currentTranslation.first.menu[4] }}</a>
        </li>
      </ul>
      <div class="corner-block social">
        <a href="#" style="margin-right: 15px" @click="toggleLanguage">{{
          language === "en" ? "DE" : "EN"
        }}</a>
        <a target="_blank" href="https://www.linkedin.com/company/dqbd-gmbh"
          ><i class="fa-brands fa-linkedin-in"></i
        ></a>
        <a href="#" @click.prevent="showModal" class="button">{{
          currentTranslation.first.headerButton
        }}</a>
      </div>
    </div>
    <div :class="{ 'mobile-menu': true, active: isMobileMenuOpened }">
      <div class="header-mobile">
        <div class="header-mobile-wrapper">
          <h2 @click.prevent="closeMobileMenu">
            <img
              class="closesign"
              src="@/assets/close_icon_new_black.png"
              alt=""
            />
          </h2>
          <a href="#" @click.prevent="closeMobileMenu"
            ><img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAWCAYAAADTlvzyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA7SURBVHgB7dRBEQAgDAPBBCUYiH85IAUcdAY6/TT7OQdHBCStm4kce8DM2mHySSI+jVlHxIeHS9Wf5gCS6gc8Ej+80wAAAABJRU5ErkJggg=="
              alt=""
          /></a>
        </div>
      </div>

      <ul class="menu">
        <li class="menu-logo"><img src="@/assets/footer-logo.png" alt="" /></li>
        <li>
          <a :href="language === 'en' ? '/en/projects' : '/de/projekte'">{{ currentTranslation.first.menu[0] }}</a>         
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-services' : '/de/unsere-leistungen'">{{ currentTranslation.first.menu[1] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/team' : '/de/team'">{{ currentTranslation.first.menu[2] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/partnerships' : '/de/partner'">{{ currentTranslation.first.menu[3] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-stories' : '/de/neueste-stories'">{{ currentTranslation.first.menu[4] }}</a>
        </li>
        <li>
          <a
            @click="
              (e) => {
                toggleLanguage();
                closeMobileMenu();
                return e;
              }
            "
            href="#"
            >{{ language === "en" ? "DE" : "EN" }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import First from "./sections/First";
import Second from "./sections/Second";
import Thrid from "./sections/Thrid";
import Services from "./sections/Services";
import Team from "./sections/Team";
import Reviews from "./sections/Reviews";
import WorkWithUs from "./sections/WorkWithUs";
import Modal from "./sections/Modal";
import Impressum from "./sections/Impressum";
import Posts from "./sections/Posts";

export default {
  name: "App",
  components: {
    First,
    Second,
    Thrid,
    Services,
    Team,
    Reviews,
    WorkWithUs,
    Modal,
    Impressum,
    Posts,
  },
  data() {
    let _lang = (
      window.navigator.userLanguage || window.navigator.language
    ).split("-")[0];
    if (_lang !== "en" && _lang !== "de") _lang = "en";
    return {
      language: _lang,
      activeSlide: 0,
      slideAdded: false,
      isFixedHeaderShown: false,
      isMobileMenuOpened: false,
      options: {
        licenseKey: "75CC33D2-76BE428B-87F9B226-0F4A3305",
        responsiveWidth: 900,
        menu: "#menu",
        anchors: [
          "page1",
          "page2",
          "page3",
          "page4",
          "page5",
          "page6",
          "page7",
        ],
        sectionsColor: ["#000", "#000", "#fff", "#000", "#fff", "#000", "#fff"],
        css3: true,
        afterLoad: this.afterLoad,
        //scrollHorizontally: false,
        scrollOverflow: true,
        resetSliders: true,
        continuousHorizontal: false,
        onSlideLeave: this.onSlideLeave,
        normalScrollElements:
          ".hidden-block, .modal-container .workWithUs-wrapper, .reviews-wrapper-scroll",
        parallax: true,
        onLeave: this.onLeave,
      },
    };
  },
  computed: {
    currentTranslation() {
      if (this.language === "en") {
        return en;
      }
      if (this.language === "de") {
        return de;
      }
    },
  },
  mounted() {
    window.SAS = this.SAS;
    window.rebuildFP = this.rebuild;
    window.showFixedHeader = this.showFixedHeader;
    window.hideFixedHeader = this.hideFixedHeader;
    window.openMobileMenu = this.openMobileMenu;
    window.closeMobileMenu = this.closeMobileMenu;
    if (window.location.pathname.indexOf("en") !== -1) {
      this.language = "en";
    } else {
      this.language = "de";
    }
  },
  methods: {
    toggleLanguage() {
      if (this.language === "en") {
        this.language = "de";
        this.$router.replace("/de");
      } else {
        this.language = "en";
        this.$router.replace("/en");
      }
    },
    showModal() {
      window.showModal();
    },
    closeMobileMenu() {
      this.isMobileMenuOpened = false;
    },
    openMobileMenu() {
      this.isMobileMenuOpened = true;
    },
    showFixedHeader() {
      this.isFixedHeaderShown = true;
    },
    hideFixedHeader() {
      this.isFixedHeaderShown = false;
    },
    rebuild() {
      this.$refs.fullpage.api.reBuild();
    },
    goDown() {
      this.$refs.fullpage.api.moveTo("page2");
    },
    SAS() {
      this.$refs.fullpage.rebuild();
    },
    setActiveSlide(index) {
      this.$refs.fullpage.api.moveTo("page2", index);
    },
    onSlideLeave(section, origin, destination, direction) {
      this.activeSlide = destination.index;
      if (origin.anchor === "page5") {
        window.closeTeamSlider();
      }
    },
    onLeave(origin, destination, direction) {
      if (origin.anchor === "page1") {
        window.closeMobileMenu();
      }
      if (destination.anchor === "page3") {
        window.startCalculateNumbers();
      }
      if (origin.anchor === "page5") {
        window.closeTeamSlider();
      }
      if (origin.anchor === "page2") {
        window.closeSecondHiddenBlock();
      }
      if (origin.anchor === "page7") {
        window.resetFooter();
      }
      if (origin.anchor === "page4") {
        window.closeServicesHiddenBlock();
      }
      if (
        destination.anchor === "page1"
        // || destination.anchor === "page7"
      ) {
        this.isFixedHeaderShown = false;
      }
      if (
        destination.anchor !== "page1"
        // && destination.anchor !== "page7"
      ) {
        this.isFixedHeaderShown = true;
      }
      if (
        destination.anchor === "page5" &&
        window.innerWidth < 767 &&
        !this.slideAdded
      ) {
        this.slideAdded = true;
        // window.addSlide();
      }
      if (destination.anchor === "page2") {
        window.secondOnLoad();
      }
    },
    afterLoad(origin, destination, direction) {
      this.pageLoaded = true;
      if (destination.isFirst) {
        window.playBG();
      }
      if (destination.anchor === "page3") {
        window.thridGoAutoplay();
      }
      if (destination.anchor === "page7") {
        window.animateFooter();
      }
      if (origin.anchor === "page7") {
        window.rebuildFP();
      }
      if (origin.anchor === "page3") {
        window.thridStopAutoplay();
      }
    },
  },
};
</script>

<style>
body * {
  color: #333;
}
.mobile-menu .menu li.menu-logo img {
  width: 100px;
}
.closesign {
  font-weight: 300;
  font-family: "Roboto";
  font-size: 30px;
  width: 21px;
}
.fp-responsive .fp-section,
.fp-responsive .fp-slide,
.fp-responsive .fp-tableCell {
  height: auto !important;
}
.fp-section.fp-table {
  overflow: hidden;
}
.fixed-header.shown {
  transform: translateY(0);
}
.fixed-header {
  top: 0;
  left: 0;
  transform: translateY(-200%);
  transition: all 0.5s;
  position: fixed;
  z-index: 9;
  width: calc(100vw - (10vw + 80px));
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  padding-left: calc(10vw + 80px);
  background: white;
  box-shadow: 0 0 3px rgb(0 0 0 / 19%);
}
.fixed-header .corner-block {
  width: unset;
}
.fixed-header .corner-block.social {
  padding-right: 140px;
}
.fixed-header .corner-block .fa-linkedin-in {
  font-size: 18px;
  color: #fff;
  background: #d9d9d9;
  padding: 10px;
  border-radius: 4px;
}
.fixed-header .logo-block img {
  width: 43px;
  margin: 0 auto;
  display: block;
}
.fixed-header .logo-block {
  position: absolute;
  left: 10vw;
  background: #fff;
  width: 80px;
  height: 57px;
  top: 0;
  display: block;
  border-bottom: 2px solid rgb(237 237 237);
  padding-top: 15px;
}
.fixed-header .social a:not(.button) {
  color: #333;
  font-size: 18px;
}

.fixed-header .social .button {
  font-size: 18px;
  background: transparent;
  border: 2px solid #333;
  color: #333;
  margin-right: 10px;
  margin-left: 16px;
  padding: 4px 10px;
}
.fixed-header .menu a {
  font-size: 18px;
}
.fixed-header .menu {
  margin-left: auto;
  margin-right: auto;
}
.fixed-header .mobile-menu-toggler {
  display: none;
}
.hidden-block .card.card-text-bottom.quoteblock * {
  color: #fff;
}
@media (max-width: 991px) {
  .fixed-header .mobile-menu-toggler {
    display: inline-block;
    font-size: 20px;
    margin-left: 20px;
  }
  .fixed-header .menu {
    display: none;
  }
  .fixed-header .corner-block.social {
    padding-right: 40px;
  }
}
@media (max-width: 410px) {
  .fixed-header .corner-block.social {
    padding-right: 10px;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #333;
  text-decoration: none;
}
* {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}
</style>
