<template>
  <div
    :class="{
      'workWithUs-container modal-container': true,
      active: isModalActive,
    }"
  >
    <div class="workWithUs-wrapper">
      <h2>
        <a href="#" @click.prevent="closeModal"
          ><img class="closesign" src="@/assets/close_icon_new_black.png" alt="" /></a>
      </h2>
      <div class="workWithUs-center">
        <div>
          <div class="packs-container2">
            <h2>Impressum</h2>
            <h3>DQBD GMBH</h3>
            Schulstrasse 15 . 73614 Schorndorf . Germany .<br />
            Phone + 49 (0) 71 81 . 937 66 60 . Mail info(at)dqbd.de<br />
            REGISTRATION COURT Stuttgart HRB747960<br />
            REGISTRATION NUMBER DE293259092<br />
            REPRESENTED BY Sebastian Hess<br />
            <h3>COPYRIGHT</h3>
            The contents and works on these websites, created by the site
            operator, are subject to German copyright legislation. Copying,
            processing, distributing and any kind of use outside the limits of
            copyright law require the written approval of the respective author
            or creator. <br />
            <h3>LIABILITY FOR CONTENT</h3>
            Our offer contains links to third-party external websites, whose
            content we cannot influence. Therefore, we can also not assume
            liability for this external content. The respective provider or
            operator is always responsible for the content of the linked
            websites. <br />
            <h3>GOOGLE ANALYTICS</h3>
            This website uses Google Analytics, a web analysis service of the
            Google Inc ("Google") Google Analytics uses so-called "Cookies",
            text files which are stored on your computer and which make it
            possible to analyse your use of the website. The information
            generated by the cookies about your use of the website (including
            your IP address) will be transmitted to and stored on a Google
            server in the United States.<br />
            Google will use this information in order to evaluate your use of
            the website, to compile reports on website activity for website
            operators and to provide other services relating to website activity
            and internet use. Google will also transfer this information to
            third parties if necessary, provided that this is required by law or
            if third parties have been commissioned to process this data on
            behalf of Google. Under no circumstances will Google link your IP
            address to other data from Google. You may prevent the use of
            cookies by selecting the appropriate settings in your browser
            software. However, please note that, in this case, the full
            functionality of this website may not be available to you. By using
            this website you are agreeing that the data collected about you by
            Google may be processed in the manner described above and for the
            purpose described above.<br />
            <h3>DQBD GMBH</h3>
            Schulstrasse 15 . 73614 Schorndorf . Deutschland<br />
            Telefon + 49 (0) 71 81 . 937 66 60 . Mail info(at)dqbd.de <br />
            Geschäftsführer Sebastian Hess<br />
            Amtsgericht Stuttgart HRB747960<br />
            Steuernummer DE293259092<br />
            Inh. verantwortlich gem. § 55 II RStV Sebastian Hess <br />
            <h3>Urheberrecht</h3>
            Die Texte, Grafiken und Fotos auf diesen Seiten unterliegen dem
            deutschen Urheberrecht. Jede Art der kommerziellen Verwertung,
            insbesondere der Bearbeitung, Verbreitung und Vervielfältigung ist
            nur mit schriftlicher Zustimmung gestattet. Beiträge Dritter sind
            als solche gekennzeichnet. <br />

            <h3>Haftungshinweis</h3>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.<br />
            <br />
            <br />
            <h2>Datenschutzerklärung</h2>
            <br />
            Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges
            Anliegen. Nachfolgend informieren wir Sie über die Art, den Umfang
            und die Zwecke der Erhebung und Verwendung personenbezogener Daten
            auf unserer Homepage. Bei Fragen zu dieser Datenschutzerklärung oder
            sonstigen Fragen zum Datenschutz im Zusammenhang mit unserem
            Internetauftritt (wie z.B. Auskunft, Änderung oder Löschung von
            Daten usw.) wenden Sie sich bitte an:<br />
            <br />

            DQBD GMBH<br />
            Schulstrasse 15 . 73614 Schorndorf . Deutschland<br />
            Telefon + 49 (0) 71 81 . 937 66 60 . Mail info(at)dqbd.de<br />
            Zuständige Person: Sebastian Hess <br />
            <h3>Datenübermittlung und Protokollierung</h3>

            Der Besuch unserer Webseite erfolgt grundsätzlich ohne dass Sie
            personenbezogene Angaben machen müssen. Beim Aufruf unserer Webseite
            sendet der von Ihnen verwendete Webbrowser bestimmte Informationen
            an unseren Server. Im Einzelnen werden über jeden Zugriff/Abruf
            folgende Daten erhoben und in sogenannten Logdateien gespeichert:<br />
            • IP-Adresse des anfragenden Rechners<br />
            • Datum und Uhrzeit des Abrufs<br />
            • Typ und Version des Webbrowsers<br />
            • Typ und Version des Betriebssystems<br />
            • ggf. URL der zuvor besuchten Webseite, wenn einer Verlinkung
            gefolgt wurde<br />
            • aufgerufene Seite/Name der aufgerufenen Datei<br />
            • Verweildauer auf den aufgerufenen Seiten.<br />
            <br />

            Die Daten werden nur zum Zwecke der statistischen Auswertung
            gesammelt und wieder gelöscht. Sie lassen keinen Rückschluss auf
            Ihre Person zu, auch eine Zusammenführung dieser Daten mit anderen
            Datenquellen erfolgt nicht. Eine Weitergabe an Dritte, zu
            kommerziellen oder nichtkommerziellen Zwecken, findet nicht statt.
            <br />
            <h3>Nutzung persönlicher Daten</h3>

            Personenbezogene Daten werden von uns nur dann erhoben, verarbeitet
            und gespeichert, wenn Sie uns diese freiwillig mitteilen. Dies ist
            zum Beispiel dann der Fall, wenn Sie uns eine E-Mail schicken,
            Informationsmaterial anfordern oder mit uns anderweitig in Kontakt
            treten. Wir nutzen Ihre persönlichen Daten ausschließlich zu
            administrativen Zwecken und zur Kommunikation mit Ihnen und zur
            Bearbeitung Ihres Anliegens. In der Regel benötigen wir Ihre
            Anschrift, Ihre Telefonnummer und/oder Ihre E-Mail-Adresse, um mit
            Ihnen in Kontakt treten zu können.
            <br />
            <h3>Auskunft und Änderung Ihrer Daten</h3>

            Sie können Auskunft über Ihre von uns verarbeiteten
            personenbezogenen Daten verlangen. Auf schriftliche Anfrage
            informieren wir Sie über die zu Ihrer Person gespeicherten Daten.
            Sollten Ihre Angaben nicht (mehr) zutreffend sein, können Sie eine
            Berichtigung verlangen. Sollten Ihre Daten unvollständig sein,
            können Sie eine Vervollständigung verlangen. Wenn wir Ihre Angaben
            an Dritte weitergegeben haben, informieren wir diese Dritten über
            Ihre Berichtigung, sofern dies gesetzlich vorgeschrieben ist.
            <br />
            <h3>
              Widerspruchsrecht; Löschung der personenbezogenen Daten;
              Beschwerde
            </h3>

            Der Nutzung und Verarbeitung Ihrer personenbezogenen Daten können
            Sie jederzeit mit Wirkung für die Zukunft durch formlose Mitteilung
            an DQBD GMBH, Schulstrasse 15, 73614 Schorndorf widersprechen. Die
            Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn Sie
            der Nutzung Ihrer Daten widersprechen oder der Zweck der
            Datenerhebung erfüllt worden ist, soweit nicht gesetzliche,
            behördliche oder vertragliche Aufbewahrungspflichten bestehen.
            Bestehen solche Pflichten, werden Ihre Daten zunächst für die
            weitere Verwendung gesperrt und nach Ablauf der Aufbewahrungsfristen
            gelöscht. Sie sind auch berechtigt, bei der zuständigen
            Datenschutzaufsichtsbehörde Beschwerde einzureichen.
            <br />
            <h3>Änderung unserer Datenschutzbestimmungen</h3>

            Wir behalten uns das Recht vor, unsere Sicherheits- und
            Datenschutzmaßnahmen zu verändern, soweit dies wegen der technischen
            Entwicklung erforderlich wird. In diesen Fällen werden wir auch
            unsere Hinweise zum Datenschutz entsprechend anpassen. Bitte
            beachten Sie daher die jeweils aktuelle Version unserer
            Datenschutzerklärung.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "Impressum",
  props: {
    translation: Object,
  },
  data() {
    return {
      isModalActive: false,
    };
  },
  mounted() {
    window.showModalImprint = this.showModal;
    window.closeModalImprint = this.closeModal;
  },
  methods: {
    closeModal() {
      this.isModalActive = false;
    },
    showModal() {
      this.isModalActive = true;
      this.animateOnLoad();
    },
    animateOnLoad() {
      gsap.to(".contacts", { duration: 0.7, opacity: 1, x: 0 });

      gsap.to(".packs-container2", {
        duration: 0.7,
        opacity: 1,
        y: 0,
      });
    },
  },
};
</script>

<style></style>
