var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services-wrapper",attrs:{"id":"services"}},[_c('div',{staticClass:"images",on:{"click":function($event){$event.preventDefault();return _vm.closeSidemenuIfOpen.apply(null, arguments)}}},_vm._l((_vm.BGimages),function(image,index){return _c('img',{key:index,class:{ active: _vm.activeImage === index },attrs:{"src":_vm.getImgUrl(image, true),"alt":""}})}),0),_c('div',{class:{ services: true, collapsed: _vm.isHiddenBlockOpen }},[_c('div',{staticClass:"mobile-slider"},[_c('swiper',{ref:"servicesSlider",staticClass:"slider",attrs:{"slides-per-view":1,"speed":500,"draggable":true},on:{"slideChange":_vm.onSlideChange}},_vm._l((_vm.translation.services.services),function(service,index){return _c('swiper-slide',{key:index},[_c('div',{class:{ item: true, active: index === _vm.activeHiddenBlock }},[_c('h2',{on:{"click":function (e) {
                  _vm.openHiddenBlock(index);
                }}},[_vm._v(" "+_vm._s(service.title)+" ")]),_c('ul',{on:{"click":function (e) {
                  _vm.openHiddenBlock(index);
                }}},_vm._l((typeof service.listshort !==
                'undefined'
                  ? service.listshort
                  : service.list),function(item,index){return _c('li',{key:index},[_c('p',[_vm._v(_vm._s(item))])])}),0),_c('a',{staticClass:"button",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal.apply(null, arguments)}}},[_vm._v(_vm._s(service.button))])])])}),1)],1),_c('div',{staticClass:"desktop-services"},_vm._l((_vm.translation.services.services),function(service,index){return _c('div',{key:index,class:{ item: true, active: index === _vm.activeHiddenBlock },on:{"click":function (e) {
            _vm.openHiddenBlock(index);
          }}},[_c('h2',{on:{"click":function (e) {
              _vm.openHiddenBlock(index);
            }}},[_vm._v(" "+_vm._s(service.title)+" ")]),_c('ul',_vm._l((typeof service.listshort !== 'undefined'
              ? service.listshort
              : service.list),function(item,index){return _c('li',{key:index},[_c('p',[_vm._v(_vm._s(item))])])}),0),_c('a',{staticClass:"button",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal.apply(null, arguments)}}},[_vm._v(_vm._s(service.button))])])}),0)]),_c('div',{ref:"servicesHiddenBlock",class:{ 'hidden-block': true, active: _vm.isHiddenBlockOpen }},[_c('div',{staticClass:"hidden-wrapper"},[_c('a',{staticClass:"close-block",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.closeHiddenBlock.apply(null, arguments)}}},[_c('img',{staticClass:"closesign",attrs:{"src":_vm.getImgUrl('close_icon_new.png'),"alt":""}})]),_c('div',{staticClass:"left-part"},[_c('h2',[_vm._v(" "+_vm._s(_vm.translation.services.services[_vm.activeHiddenBlock].titleSecond)+" ")]),_c('ul',_vm._l((_vm.translation.services.services[
              _vm.activeHiddenBlock
            ].list),function(item,index){return _c('li',{key:index},[_c('p',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"right-part"},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.translation.services.services[_vm.activeHiddenBlock].info.topText
          )}}),_c('img',{attrs:{"src":_vm.translation.services.services[_vm.activeHiddenBlock].info.image,"alt":""}}),_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.translation.services.services[_vm.activeHiddenBlock].info.bottomText
          )}}),_c('img',{attrs:{"src":_vm.translation.services.services[_vm.activeHiddenBlock].info.image2,"alt":""}}),_c('div',{staticClass:"card card-text-bottom quoteblock"},[_c('div',{staticClass:"card-text"},[_c('p',[_c('img',{attrs:{"src":_vm.getImgUrl('quotes.png'),"alt":""}}),_c('span',{domProps:{"innerHTML":_vm._s(
                  _vm.translation.services.services[_vm.activeHiddenBlock].info
                    .quoteText
                )}})])])]),_c('a',{staticClass:"button",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.showModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.translation.services.services[_vm.activeHiddenBlock].button))]),_c('div',{staticClass:"last-block"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }