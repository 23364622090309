<template>
  <div style="position: relative">
    <div class="first-bg-container">
      <p id="claim-top" style="opacity:0">dream design do</p>
      <video  
        playsinline
        autoplay
        muted
        loop
        :poster="video.poster"
        id="bgvideo"
        ref="bgvideo"
        :width="video.width"
        :height="video.height"
        style="object-fit: cover; width: 100%;min-height: 100vh; height: 100vh;"
        @pause="pausedPlaying"
        @play="startedPlaying"
      >
        <source :src="video.videoUrl" type="video/mp4" />
      </video>
    </div>
    <div class="first-section">
      <div class="header-mobile">
        <div class="header-mobile-wrapper">
          <img :src="getImgUrl('mobile-logo.png')" alt="" />
          <a href="#" @click.prevent="openMobileMenu"
            ><img :src="getImgUrl('mobile-bar_white.png')" alt=""
          /></a>
        </div>
      </div>
      <div class="header">
        <a href="#" class="corner-block">
          <img src="@/assets/Logo_w.png" alt="" />
        </a>
        <ul class="menu">
        <li>
          <a :href="language === 'en' ? '/en/projects' : '/de/projekte'">{{ translation.first.menu[0] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-services' : '/de/unsere-leistungen'">{{ translation.first.menu[1] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/team' : '/de/team'">{{ translation.first.menu[2] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/partnerships' : '/de/partner'">{{ translation.first.menu[3] }}</a>
        </li>
        <li>
          <a :href="language === 'en' ? '/en/our-stories' : '/de/neueste-stories'">{{ translation.first.menu[4] }}</a>
        </li>
		 
        </ul>
        <div class="corner-block social">
          <a href="#" style="margin-right: 15px" @click="toggleLanguage">{{
            language === "en" ? "DE" : "EN"
          }}</a>
          <a target="_blank" href="https://www.linkedin.com/company/dqbd-gmbh"
            ><i class="fa-brands fa-linkedin-in"></i
          ></a>
          <a href="#" @click.prevent="showModal()" class="button">{{
            translation.first.headerButton
          }}</a>
        </div>
      </div>
      <div class="main-text">
        <h1></h1>
      </div>
      <a
        :class="{ 'button call-to-action': true, active: isCallToActionActive }"
        @click.prevent="showModal()"
        href="#"
        >{{ translation.first.mainButton }}</a
      >
      <a href="#" class="go-down" @click.prevent="goDown"
        ><img src="@/assets/arrow-down.png" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "First",
  props: {
    goDown: Function,
    translation: Object,
    toggleLanguage: Function,
    language: String,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      video: {
        videoUrl: (window.innerWidth < 1045 ? "/img/mobile_video.mp4" : this.translation.first.video.videoUrl),
        videoType: this.translation.first.video.videoType,
        poster: (window.innerWidth < 600 ? "/img/mobile_image_video.jpg" : this.translation.first.video.poster),
        height: window.innerHeight,
        width: window.innerWidth,
      },
      isCallToActionActive: false,
      callToActionTimeout: null,
    };
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if(newWidth < 1045){
         this.video.videoUrl="/img/mobile_video.mp4";
	  }else{
         this.video.videoUrl=this.translation.first.video.videoUrl;
      }
    }
  },
  mounted() { 
  // alert(window.innerWidth+'-'+window.innerHeight);
    const _t = this;
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
    _t.showsBuffer = false;
    window.playBG = function () {
      _t.$refs.bgvideo.play();
    };
    window.pauseBG = function () {
      _t.$refs.bgvideo.pause();
    };
  },
  methods: {
    // oncanplay (e) {
    // if(!this.showsBuffer){
    // this.$refs.bgvideo.pause();
    // setTimeout(() => {
    // this.showsBuffer=true;
    // this.isCallToActionActive = true;
    // this.$refs.bgvideo.play();
    // }, 2000);
    // }
    // console.log("canstartplaying", e);
    // },
    onResize() {
      this.windowWidth = window.innerWidth;
    },    
    startedPlaying(e) {
      if (this.isCallToActionActive) {
        return;
      }
      const timeoutTime =
        e.currentTarget.duration * 1000 -
        e.currentTarget.currentTime * 1000 -
        1000;
      if (this.callToActionTimeout !== null) {
        clearTimeout(this.callToActionTimeout);
      } else {
        this.callToActionTimeout = setTimeout(() => {
          this.isCallToActionActive = true;
        }, timeoutTime);
      }
      document.getElementById("claim-top").style.display = "none";
      console.log("startedPlaying", e);
    },
    pausedPlaying() {
      if (this.isCallToActionActive) {
        return;
      }
      clearTimeout(this.callToActionTimeout);
      this.callToActionTimeout = null;
    },

    showModal(tag) {
      window.showModal(tag);
    },
    openMobileMenu() {
      window.openMobileMenu();
    },
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
  },
};
</script>

<style>
.mobile-menu {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 100;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  text-align: center;
  transform: translateX(100%);
}
#claim-top {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 999;
  font-size: 119px;
  color: #fff;
  text-align: center;
  top: 0;
  font-weight: 900;
  margin-top: 38vh;
  word-spacing: 29px;
}
.mobile-menu .close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #000;
}
.mobile-menu .menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.mobile-menu .menu li {
  margin-right: 0;
  margin-bottom: 30px;
}
.mobile-menu .menu li a {
  color: #000;
}
.mobile-menu.active {
  transform: translateX(0);
}
.header .menu li a {
  color: #fff;
}
.header-mobile-wrapper {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-mobile {
  position: absolute;
  top: 20px;
  max-width: 100%;
  z-index: 5;
  width: 100%;
}
.first-section .header-mobile {
  display: none;
}
.first-bg-container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.first-bg-container video {
  width: 100%;
  height: 100%;
}
.header {
  position: relative;
  z-index: 2;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 78%;
  margin: 0 auto;
}
.first-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.menu {
  font-size: 20px;
  line-height: 23px;
  margin: 0;
}
.mobile-menu .menu li.menu-logo {
  margin-bottom: 10vh;
}
@media (max-height: 600px) {
  .mobile-menu .menu li.menu-logo {
    display: none;
  }
}
.menu li {
  margin: 0;
  margin-right: 3.7vw;
  line-height: 30px;
}
.menu li:last-of-type {
  margin-right: 0;
}
.corner-block {
  width: 185px;
}
.social {
  font-size: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social .button {
  border: 2px solid #fff;
  background: transparent;
  color: white;
  margin-left: 20px;
  padding: 5px 11px;
  font-size: 18px;
}
.social a:not(.button) {
  font-size: 18px;
}
.social a:not(.button),.social a i{
  color: #fff;
}
.main-text {
  position: relative;
  z-index: 2;
  padding: 0 15% 0 15%;
}
.main-text h1 {
  text-align: center;
  text-transform: uppercase;
  font-size: 3.2vw;
  margin: 0;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 800;
  font-size: 130px;
  opacity: 0.5;
}
.button {
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  padding: 13px 25px;
  font-size: 22px;
  line-height: 26px;
  color: #000;
  z-index: 2;
  display: block;
}
.first-section .call-to-action.active {
  opacity: 1;
  z-index: 2;
  visibility: visible;
}
.first-section .call-to-action {
  margin-left: auto;
  margin-right: auto;
  transform: translateY(72px);
  opacity: 0;
  z-index: -1;
  visibility: hidden;
}
.first-section .go-down:hover {
  transform: translateY(5px);
}
.first-section .go-down {
  transition: all 0.3s;
  transform: translateY(0px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
@media (max-width: 1299px) {
  .corner-block {
    width: auto;
  }
}
@media (max-width: 1199px) {
  .menu li {
    margin-right: 2.5vw;
  }
}
@media (max-width: 991px) {
  #claim-top {
    margin-top: 50%;
    font-size: 108px;
  }
  .main-text h1 {
    font-size: 28px;
  }
  .header .menu,
  .header .social {
    display: none;
  }
  .first-section .header-mobile {
    display: block;
  }
  .header {
    justify-content: center;
  }
  .header .corner-block {
    display: none;
  }
}
@media (max-width: 375px) {
  .main-text h1 {
    font-size: 22px;
  }
}
</style>
