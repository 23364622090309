<template>
  <div class="second">
    <div class="bg-image">
      <swiper
        :slides-per-view="1"
        :space-between="0"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :speed="1000"
        :draggable="false"
        class="slider"
        ref="swiperBG"
        :parallax="true"
      >
        <swiper-slide
          class="item"
          v-for="project in projects"
          :key="project.id + 'swiperBG'"
        >
          <img
            :style="!isBlurDisabled ? 'filter: blur(12px);' : ''"
            :src="project.bgImage"
            alt=""
          />
        </swiper-slide>
      </swiper>
      <div class="nav">
        <button class="prev" @click="slidePrev" v-show="isPrevBtnShowed">
          <img :src="getImgUrl('arrow-left.png')" alt="" />
        </button>
        <button class="next" @click="slideNext" v-show="isNextBtnShowed">
          <img :src="getImgUrl('arrow-right.png')" alt="" />
        </button>
      </div>
      <div class="dots">
        <a
          v-for="project in projects"
          class="dot-container"
          :key="project.id + 'dot'"
          @click="
            (e) => {
              setActiveSlide(project.id);
            }
          "
        >
          <span
            :class="{ dot: true, active: activeSlide === project.id }"
          ></span
        ></a>
      </div>

      <div v-show="!isHiddenBlockOpened" id="round" @click="openHiddenBlock">
        <swiper
          :slides-per-view="1"
          :space-between="0"
          @swiper="onSwiperRound"
          @slideChange="onSlideChangeRound"
          :speed="1200"
          class="slider-round"
          ref="swiperBG-round"
        >
          <swiper-slide
            class="item"
            v-for="project in projects"
            :key="project.id + 'swiperBG'"
          >
            <img :src="project.bgImage" alt="" />
          </swiper-slide>
        </swiper>
        <img :src="getImgUrl('hoverBGSecond.png')" class="hover-img" alt="" />
        <div class="hover-block">
          <h4>{{ projects[activeSlide].moreinfo }}</h4>
        </div>
        <svg
          version="1.1"
          id="layout-svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 841.89 595.28"
          style="enable-background: new 0 0 841.89 595.28"
          xml:space="preserve"
        >
          <path
            d="M595.19,475.53c46.1-45.23,74.7-108.23,74.7-177.91c0-137.64-111.58-249.22-249.22-249.22
	S171.45,159.98,171.45,297.62s111.58,249.22,249.22,249.22c0.1-0.39,0.07-0.86,0-1.76c-136.67,0-247.45-110.79-247.45-247.45
	S284,50.16,420.67,50.16c133.44,0,242.21,105.63,247.27,237.83c0.12,3.19-5.94,6.48-5.94,9.62c0,65.98-26.48,125.78-69.39,169.35
	C590.69,468.9,593.99,476.71,595.19,475.53z"
          />
        </svg>
      </div>
    </div>
    <div class="text">
      <h2>{{ translation.second.title }}</h2>
      <p v-html="textForActiveSlide"></p>
    </div>
    <a href="#" class="button call-to-action" @click.prevent="showModal">{{
      translation.second.button
    }}</a>
    <div :class="{ 'hidden-block': true, active: isHiddenBlockOpened }">
      <div class="hidden-overflow" @click.prevent="closeHiddenBlock"></div>
      <div class="hidden-wrapper">
        <a href="#" class="close-block" @click.prevent="closeHiddenBlock"
          ><img class="closesign" :src="getImgUrl('close_icon_new.png')" alt=""
        /></a>
        <div class="dots">
          <a
            v-for="project in projects"
            class="dot-container"
            :key="project.id"
            @click="
              (e) => {
                setActiveSlide(project.id);
              }
            "
          >
            <span
              :class="{ dot: true, active: activeSlide === project.id }"
            ></span
          ></a>
        </div>
        <h2 v-html="getProjectName"></h2>
        <div class="card card-text-bottom">
          <video
            v-if="
              typeof projects[activeSlide] !== 'undefined'
                ? projects[activeSlide].details.topBlock.video
                : ''
            "
            playsinline=""
            autoplay="autoplay"
            muted="muted"
            loop="loop"
            :poster="projects[activeSlide].details.topBlock.image"
            id="bgvideo"
            style="object-fit: cover; height: 509px; width: 100%"
          >
            <source
              :src="projects[activeSlide].details.topBlock.video"
              type="video/mp4"
            />
          </video>
          <img
            v-else
            :src="
              typeof projects[activeSlide] !== 'undefined'
                ? projects[activeSlide].details.topBlock.image
                : ''
            "
            alt=""
          />
          <div class="card-text">
            <p v-html="getProjectTopText"></p>
          </div>
        </div>
        <swiper
          :slides-per-view="5"
          :space-between="43"
          :speed="500"
          class="humans-slider"
          :autoplay="{
            delay: 1000,
          }"
          :breakpoints="{
            // when window width is >= 320px
            320: {
              slidesPerView: 3,
            },
            // when window width is >= 480px
            767: {
              slidesPerView: 4,
            },
            // when window width is >= 640px
            991: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 5,
            },
          }"
          ref="humansSlider"
        >
          <swiper-slide v-for="(human, index) in getHumans" :key="index">
            <img :src="getImgUrl(human.image)" alt="" />
            <p>{{ human.name }}</p>
          </swiper-slide>
        </swiper>
        <div class="keywordsBlock">
          <div class="keywordsBlock-title">
            <h2>{{ secondBlockTitle }}</h2>
          </div>
          <div class="keywordsBlock-info">
            <p v-html="secondBlockDescription"></p>
            <swiper
              :slides-per-view="3"
              :space-between="34"
              :speed="500"
              class="blackLogo-slider"
              ref="blackLogoSlider"
              :draggable="true"
              :breakpoints="{
                320: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }"
            >
              <swiper-slide
                v-for="(image, index) in getBlacksSliderImages"
                :key="index"
              >
                <img :src="getImgUrl(image)" alt="" />
              </swiper-slide>
            </swiper>
          </div>
        </div>

        <div
          class="first-step step"
          v-for="(firstblock, id) in getFirstBlocks"
          :key="id + 'first'"
        >
          <video
            v-if="
              typeof projects[activeSlide] !== 'undefined' &&
              firstblock.video &&
              firstblock.video !== ''
            "
            playsinline=""
            autoplay="autoplay"
            muted="muted"
            loop="loop"
            :poster="
              typeof projects[activeSlide] !== 'undefined'
                ? firstblock.image
                : ''
            "
            id="bgvideo"
            style="object-fit: cover; height: 509px; width: 100%"
          >
            <source :src="firstblock.video" type="video/mp4" />
          </video>
          <img
            v-else
            :src="
              typeof projects[activeSlide] !== 'undefined'
                ? firstblock.image
                : ''
            "
            alt=""
          />
          <div class="text-block">
            <div class="title-container">
              <h3
                v-html="
                  typeof projects[activeSlide] !== 'undefined'
                    ? firstblock.title
                    : ''
                "
              ></h3>
              <h3 class="subtitle">
                {{
                  typeof projects[activeSlide] !== "undefined"
                    ? firstblock.subtitle
                    : ""
                }}
              </h3>
            </div>

            <p
              v-html="
                typeof projects[activeSlide] !== 'undefined'
                  ? firstblock.description
                  : ''
              "
            ></p>
            <a href="#" style="display: none" class="button d-none"
              >Mehr Infos</a
            >
          </div>
        </div>

        <div
          v-if="typeof projects[activeSlide].details.thirdBlock !== 'undefined'"
          class="card card-text-bottom"
        >
          <img
            :src="
              typeof projects[activeSlide] !== 'undefined' &&
              typeof projects[activeSlide].details.thirdBlock !== 'undefined'
                ? projects[activeSlide].details.thirdBlock.image
                : ''
            "
            alt=""
          />
          <div class="card-text">
            <p>
              {{ getProjectThirdText }}
            </p>
          </div>
        </div>
        <div
          v-if="typeof projects[activeSlide].details.quoteBlock !== 'undefined'"
          class="card card-text-bottom quoteblock"
        >
          <div class="card-text">
            <p>
              <img :src="getImgUrl('quotes.png')" alt="" />
              <span>{{ getProjectQuotesText }}</span>
            </p>
          </div>
          <img
            :src="
              typeof projects[activeSlide] !== 'undefined' &&
              typeof projects[activeSlide].details.quoteBlock !== 'undefined'
                ? projects[activeSlide].details.quoteBlock.image
                : ''
            "
            alt=""
          />
        </div>
        <div
          v-if="
            typeof projects[activeSlide].details.stepsSliders !== 'undefined'
          "
          class="bottom-slider-container"
        >
          <swiper
            :slides-per-view="1"
            :space-between="65"
            :speed="500"
            class="bottom-slider"
            ref="bottomSlider"
            :draggable="true"
            @slideChange="onBottomSlideChange"
          >
            <swiper-slide
              v-for="(slide, index) in getBottomSliderImages"
              :key="index"
            >
              <img :src="slide.image" alt="" />
            </swiper-slide>
          </swiper>
          <div class="bottom-navs">
            <button class="prev" @click="bottomSliderPrev">
              <img :src="getImgUrl('arrow-left.png')" alt="" />
            </button>
            <button class="next" @click="bottomSliderNext">
              <img :src="getImgUrl('arrow-right.png')" alt="" />
            </button>
          </div>

          <div
            class="step"
            v-show="slide.id === activeBottomSlideId"
            v-for="(slide, index) in getBottomSliderImages"
            :key="index"
          >
            <div class="text-block">
              <div class="title-container">
                <h3>{{ slide.title }}</h3>
              </div>

              <p>{{ slide.description }}</p>
              <a href="#" class="button">Mehr Infos</a>
            </div>
          </div>
        </div>
        <a href="#" class="button call-to-action" @click.prevent="showModal">{{
          translation.second.button
        }}</a>
        <div class="last-block"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "Second",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    translation: Object,
  },
  watch: {
    activeSlide(prevValue, nextValue) {
      if (prevValue !== undefined && this.isHiddenBlockOpened) {
        this.updateSliders();
      }
    },
  },
  computed: {
    getBottomSliderImages() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.stepsSliders
        : "";
    },
    getProjectThirdText() {
      return typeof this.projects[this.activeSlide] !== "undefined" &&
        typeof this.projects[this.activeSlide].details.thirdBlock !==
          "undefined"
        ? this.projects[this.activeSlide].details.thirdBlock.description
        : "";
    },
    getProjectQuotesText() {
      return typeof this.projects[this.activeSlide] !== "undefined" &&
        typeof this.projects[this.activeSlide].details.quoteBlock !==
          "undefined"
        ? this.projects[this.activeSlide].details.quoteBlock.description
        : "";
    },
    getProjectTopText() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.topBlock.text
        : "";
    },
    getProjectTopImage() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.topBlock.image
        : "";
    },
    getProjectName() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].title
        : "";
    },
    firstStepTitle(id) {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.firstStepBlock[id].title
        : "";
    },
    firstStepSubtitle(id) {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.firstStepBlock[id].subtitle
        : "";
    },
    getFirstBlocks() {
      if (
        typeof this.projects[this.activeSlide] !== "undefined" &&
        this.projects[this.activeSlide].details.firstStepBlock?.length
      ) {
        return this.projects[this.activeSlide].details.firstStepBlock;
      } else return [this.projects[this.activeSlide].details.firstStepBlock];
    },
    firstStepDescription() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.firstStepBlock.description
        : "";
    },
    secondBlockTitle() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.secondBlock.title
        : "";
    },
    secondBlockDescription() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.secondBlock.description
        : "";
    },
    getBlacksSliderImages() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.secondBlock.images
        : [];
    },
    getHumans() {
      return typeof this.projects[this.activeSlide] !== "undefined"
        ? this.projects[this.activeSlide].details.peoples
        : [];
    },
    textForActiveSlide() {
      return this.projects[this.activeSlide].title;
    },
    projects() {
      return this.translation.second.projects;
    },
  },

  data() {
    return {
      isBlurDisabled: false,
      isHiddenBlockOpened: false,
      isPrevBtnShowed: false,
      isNextBtnShowed: true,
      activeSlide: 0,
      humansAutoplay: null,
      activeBottomSlideId: 0,
      // projects: this.translation.second.projects,
    };
  },
  mounted() {
    // var container = document.querySelector(".second #round");
    // var path = document.querySelector("#layout-svg path");
    // var svg = document.querySelector("#layout-svg");
    // function scaleCSSMP() {
    //   //will want to debounce this
    //   const computedWidth =
    //     getComputedStyle(container).height < getComputedStyle(container).width
    //       ? getComputedStyle(container).height
    //       : getComputedStyle(container).width;
    //   var width = parseInt(computedWidth, 10);
    //   path.style.width = computedWidth;
    //   path.style.height = computedWidth;
    //   //width = computedWidth + "px";
    //   svg.setAttribute(
    //     "style",
    //     `width: ${computedWidth}; height: ${computedWidth}; display: ${svg.style.display}`
    //   );
    //   console.log(svg.style);
    // }

    // scaleCSSMP();

    // window.addEventListener("resize", scaleCSSMP);

    const roundArea = document.querySelector("#round");
    const _t = this;
    roundArea.addEventListener("mouseover", () => {
      _t.isBlurDisabled = true;
      // svg.style.opacity = 1;
    });
    roundArea.addEventListener("mouseout", () => {
      _t.isBlurDisabled = false;
      // svg.style.opacity = 0;
    });
    window.slup = () => {
      this.updateSliders();
    };
    window.closeSecondHiddenBlock = this.closeHiddenBlock;
    window.secondOnLoad = this.onLoadAnimation;
  },
  methods: {
    showModal() {
      window.showModal("light");
    },
    onLoadAnimation() {
      this.animateDots();
      this.animateNavs();
      this.animateHeader();
      this.animateCenter();
    },
    animateDots() {
      var tl = gsap.timeline();
      tl.from(".second .dots", { duration: 1, right: "-5vw" });
    },
    animateHeader() {
      var tl = gsap.timeline();
      tl.fromTo(
        ".second .text h2",
        { y: "-100px", opacity: 0 },
        { duration: 1, y: 0, opacity: 1 }
      ).fromTo(
        ".second .text p",
        { y: "-100px", opacity: 0 },
        { duration: 0.5, y: 0, opacity: 1 }
      );
    },
    animateCenter() {
      var tl = gsap.timeline();
      tl.fromTo(
        ".second #round",
        { scale: 0.2, opacity: 0 },
        { duration: 1, scale: 1, opacity: 1 }
      ).fromTo(
        ".second .call-to-action",
        { y: 100 },
        { duration: 1, y: 0, delay: -1 }
      );
    },
    animateNavs() {
      var tl = gsap.timeline();
      tl.fromTo(
        ".second .nav .next",
        { right: "-40px", opacity: 0 },
        { duration: 1, right: "40px", opacity: 1 }
      );
    },
    createHumansSliderAutoplay() {
      const _t = this;

      this.humansAutoplay = setTimeout(() => {
        if (_t.$refs.humansSlider.swiperRef.isEnd) {
          _t.$refs.humansSlider.swiperRef.slideTo(0, 1000);
        } else {
          _t.$refs.humansSlider.swiperRef.slideNext();
        }
        _t.createHumansSliderAutoplay();
      }, 5000);
    },
    deleteHumansSliderAutoplay() {
      clearTimeout(this.humansAutoplay);
    },
    slidePrev() {
      if (this.$refs.swiperBG) {
        this.$refs.swiperBG.swiperRef.slidePrev();
      }
    },
    slideNext() {
      if (this.$refs.swiperBG) {
        this.$refs.swiperBG.swiperRef.slideNext();
      }
    },
    closeHiddenBlock() {
      this.isHiddenBlockOpened = false;
      window.showFixedHeader();
      this.deleteHumansSliderAutoplay();
    },
    openHiddenBlock() {
      this.isHiddenBlockOpened = true;
      window.hideFixedHeader();
      this.createHumansSliderAutoplay();
      this.updateSliders();
      this.$nextTick(() => {
        document.querySelector(".second .hidden-wrapper").scrollTop = 0;
      });
    },
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
    updateSliders() {
      setTimeout(() => {
        if (this.$refs.humansSlider) {
          this.$refs.humansSlider.swiperRef.slideTo(0, 0);
          this.$refs.humansSlider.swiperRef.update();
        }
        if (this.$refs.blackLogoSlider) {
          this.$refs.blackLogoSlider.swiperRef.slideTo(0, 0);

          this.$refs.blackLogoSlider.swiperRef.update();
        }
        if (this.$refs.bottomSlider) {
          this.$refs.bottomSlider.swiperRef.slideTo(0, 0);

          this.$refs.bottomSlider.swiperRef.update();
        }
      }, 200);
    },
    onSlideChange(swiper) {
      this.$nextTick(() => {
        document.querySelector(".second .hidden-wrapper").scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
      this.activeSlide = swiper.activeIndex;
      this.$refs["swiperBG-round"].swiperRef.slideTo(swiper.activeIndex, 1000);
      if (swiper.isEnd) {
        this.isNextBtnShowed = false;
      } else {
        this.isNextBtnShowed = true;
      }
      if (!swiper.isBeginning) {
        this.isPrevBtnShowed = true;
      } else {
        this.isPrevBtnShowed = false;
      }
    },
    onSwiper(swiper) {
      document.querySelector(".bg-image .swiper-wrapper")[0];
      if (swiper.isBeginning) {
        this.isPrevBtnShowed = false;
        this.isNextBtnShowed = true;
      }
    },
    onSwiperRound(swiper) {},
    onSlideChangeRound(swiper) {},
    setActiveSlide(index) {
      this.activeSlide = index;
      this.$refs.swiperBG.swiperRef.slideTo(index, 1000);
    },
    onBottomSlideChange(swiper) {
      this.activeBottomSlideId = swiper.activeIndex;
    },
    bottomSliderNext() {
      if (this.$refs.bottomSlider) {
        this.$refs.bottomSlider.swiperRef.slideNext();
      }
    },
    bottomSliderPrev() {
      if (this.$refs.bottomSlider) {
        this.$refs.bottomSlider.swiperRef.slidePrev();
      }
    },
  },
};
</script>

<style>
.second .text * {
  color: #fff;
}
.bg-image .slider-round.swiper-container {
  transition-timing-function: ease-in-out;
}
.slider-round {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: calc((100vw - 100%) / 2 * -1);
  top: calc((100vh - 100%) / 2 * -1 + 30px);
  z-index: 3;
}
.hidden-block .hidden-overflow {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  left: 0;
  background: transparent;
  top: 0;
}
.hidden-block.active .hidden-overflow {
  left: calc((100vw - 100%) * -1);
}
#layout-svg {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: white;
  z-index: 6;
  transition: all 1s;
}
#layout-svg path {
  transform: scale(1.65);
  transform-origin: center;
}
#round #motion-svg path {
  stroke-width: 10px;
  stroke: white;
  stroke-dasharray: 2100;
  stroke-dashoffset: 2100;
  animation: dash 0.5s linear;
  animation-fill-mode: forwards;
}
@keyframes dash {
  100% {
    stroke-dashoffset: 4200;
  }
}
.bottom-slider-container .bottom-navs button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.bottom-slider-container .bottom-navs button img {
  width: 15px;
}
.bottom-slider-container .bottom-navs .prev {
  padding: 10px 25px 10px 0;
}
.bottom-slider-container .bottom-navs .next {
  padding: 10px 0 10px 25px;
}
.bottom-slider-container {
  overflow: hidden;
}
.bottom-slider {
  width: 857px;
}
.bottom-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.step.first-step {
  margin-top: 62px;
  margin-bottom: 62px;
}
.step .text-block .button {
  display: inline-block;
  font-size: 18px;
  padding: 11px 22px;
}
.step .text-block h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #fff;
  margin: 0;
  margin-bottom: 14px;
}
.step p {
  font-weight: normal;
  font-size: 22px;
  line-height: 36px;
  color: #fff;
  margin: 0;
  margin-bottom: 32px;
}
.step .text-block {
  margin-left: 17%;
  margin-top: -85px;
  background: black;
  z-index: 9;
  position: relative;
  padding-top: 68px;
  padding-left: 138px;
  padding-bottom: 68px;
}
.step .text-block .title-container {
  display: flex;
}
.step .text-block .title-container h3 {
  margin-right: 18px;
}
.step .text-block .title-container h3.subtitle {
  opacity: 0.5;
}
.blackLogo-slider .swiper-wrapper {
  align-items: center;
}
.blackLogo-slider {
  width: 100%;
  /* max-width: 700px; */
  overflow: hidden;
}
.blackLogo-slider img {
  width: 100%;
}
.hidden-block .keywordsBlock-info h3 {
  font-size: 30px;
  line-height: 44px;
  margin: 0;
  margin-bottom: 15px;
}
.keywordsBlock-info {
  width: calc(100% - 275px);
}
.keywordsBlock .keywordsBlock-title {
  margin-right: 64px;
}
.keywordsBlock p {
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 36px;
}
  strong,
  h4,
  h5 {
    font-weight: 600;
    color: #fff;
  }
.hidden-block .keywordsBlock h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-transform: none;
  color: #ffffff;
}
.keywordsBlock {
  display: flex;
  margin-top: 30px;
}
.humans-slider {
  margin-top: 60px;
  overflow: hidden;
}
.humans-slider img {
  width: 100%;
}
.humans-slider p {
  font-size: 22px;
  line-height: 36px;
  color: #fff;
}
.last-block {
  margin-bottom: 150px;
}
.card {
  position: relative;
  display: flex;
  padding-bottom: 60px;
}
.card.quoteblock {
  position: relative;
  display: flex;
  padding-bottom: 60px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}
.card img {
  width: 100%;
}
.card.quoteblock img {
  width: 50%;
}
.card.quoteblock .card-text img {
  filter: brightness(1.5);
  display: block;
  width: 28%;
  max-width: 100px;
  margin: 0 0 20px;
  min-width: 50px;
}
.card.quoteblock .card-text {
  width: 50%;
  color: #fff;
  padding: 40px;
  background: none;
  position: relative;
}
.card-text {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 310px;
  padding: 40px;
}
.card-text p {
  font-size: 22px;
  line-height: 36px;
  margin: 0;
}
.hidden-wrapper {
  padding: 110px 123px 110px 150px;
  overflow: scroll;
  height: 100%;
  position: relative;
  z-index: 2;
  background: black;
}
.hidden-block .close-block {
  position: absolute;
  top: 40px;
  font-size: 30px;
  color: #fff;
  left: 70px;
}
.hidden-block {
  position: absolute;
  top: 0;
  right: 0;
  width: 85%;
  max-width: 1290px;
  height: 100%;
  background: #000;
  z-index: 7;
  -webkit-box-shadow: -10px 0px 8px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: -10px 0px 8px 0px rgb(0 0 0 / 20%);
  box-shadow: -10px 0px 8px 0px rgb(0 0 0 / 20%);
  transform: translateX(100%);
  transition: all 0.5s;
}
.hidden-block.active {
  transform: translateX(0);
}
.hidden-block h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  color: #fff;
  margin: 0;
  margin-bottom: 23px;
}
.hidden-block h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;

  color: #fff;
  margin: 0;

  margin-bottom: 30px;
}
.slide {
  position: relative;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.second {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.second .nav .prev {
  position: absolute;
  z-index: 700;
  left: 40px;
  top: calc(50vh - 25px);
  background: transparent;
  border: none;
  cursor: pointer;
}
.second .nav .next {
  position: absolute;
  z-index: 700;
  right: 40px;
  top: calc(50vh - 25px);
  background: transparent;
  border: none;
  cursor: pointer;
}
.bg-image {
  width: 100%;
  height: 100%;
}
#blur-area.active {
  backdrop-filter: blur(0px);
  filter: blur(0px);
}
#blur-area {
  filter: blur(12px);
  width: 100%;
  height: 100%;
  transition: all 0.6s;
  backdrop-filter: blur(12px);
  mask-image: radial-gradient(
    35vh at 50% calc(50% - 30px),
    transparent 100%,
    black 100%
  );
  -webkit-mask-image: radial-gradient(
    35vh at 50% calc(50% - 30px),
    transparent 100%,
    black 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.bg-image .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg-image > img {
  margin-left: -5%;
  margin-top: -5%;
  width: 110%;
  height: 120%;
  object-fit: cover;
}
#round {
  position: absolute;
  top: calc(50% - 35vh - 30px);
  left: calc(50% - 35vh);
  width: 70vh;
  height: 70vh;
  z-index: 5;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  overflow: hidden;
}
#round .item img {
  transform: scale(1.5);
  transition: all 0.5s;
}
#round:hover .item img {
  transform: scale(1);
}
#round:hover #layout-svg {
  transform: rotate(-270deg);
}
#round:hover .hover-img,
#round:hover .hover-block {
  opacity: 1;
}
#round .hover-img {
  display: none !important;
  content: "";
  width: 90%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 5%;
  z-index: 4;
}
#round .hover-block h4 {
  margin: 0;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
}
#round .hover-block {
  position: absolute;
  top: calc(50% - 55px);
  z-index: 5;
  padding: 0;
  width: 100%;
  left: 0;
  height: 100px;

  text-align: center;
  transition: all 0.3s;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
#round #motion-svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
  display: block;
}
.text {
  position: absolute;
  width: 450px;
  top: 0;
  color: #fff;
  left: 10vw;
  top: 10vh;
  z-index: 5;
}
.fp-controlArrow {
  z-index: 6 !important;
  border: none !important;
}
.fp-controlArrow.fp-prev {
  background: url("../assets/arrow-left.png");
  width: 25px !important;
  height: 50px;
  left: calc(5vw + 25px) !important;
}
.fp-controlArrow.fp-next {
  background: url("../assets/arrow-right.png");
  width: 25px !important;
  height: 50px;
  right: calc(5vw + 25px) !important;
}
.text h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0;
}
.text p {
  font-size: 22px;
  line-height: 36px;
  margin: 0;
}
.second .call-to-action {
  position: absolute;
  bottom: 5vh;
  left: calc(50% - 102px);
  z-index: 5;
}
.second .hidden-wrapper .call-to-action {
  position: relative;
  width: auto;
  display: inline-block;
  margin: 40px auto 0;
}
.hidden-block .dots {
  top: 40px;
  right: 40px;
}
.dots {
  position: absolute;
  bottom: calc(5vh + 18px);
  right: calc(5vw);
  display: flex;
  z-index: 6;
}
.dots .dot-container {
  padding: 9px;
}
.dots .dot {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 0.2;
  transition: all 0.3s;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}
.dots .dot.active {
  opacity: 1;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(30px);
  opacity: 0;
}
.bg-image .slider {
  height: 100%;
}
.bg-image .slider img {
  transition: all 1s;
}
.hidden-block .step.first-step img {
  width: 100%;
}

@media (max-width: 1199px) {
  .second .text {
    width: 300px;
  }
  .second .text h2 {
    font-size: 30px;
  }
  .second .text p {
    font-size: 16px;
  }
  .hidden-wrapper {
    padding: 90px;
  }
  .hidden-block h2 {
    font-size: 44px;
    line-height: 50px;
  }
  .hidden-block .card-text {
    padding: 30px;
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .card.quoteblock img {
    width: 50%;
  }
  .bg-image .slider img {
    filter: blur(6px) !important;
  }
  .second .hidden-wrapper .call-to-action {
    bottom: 0;
  }
  .hidden-block {
    width: 100%;
  }
  .second #round {
    top: calc(50% - 25vh);
    left: calc(50% - 25vh);
    width: 50vh;
    height: 50vh;
  }
  .second #round .hover-block h4 {
    font-size: 30px;
  }
  .second #round .hover-block {
    top: calc(50% - 43px);
    height: 70px;
  }
  .second #blur-area {
    mask-image: radial-gradient(
      25vh at 50% calc(50%),
      transparent 100%,
      black 100%
    );
    -webkit-mask-image: radial-gradient(
      25vh at 50% calc(50%),
      transparent 100%,
      black 100%
    );
  }
  .second .text {
    top: 77px;
  }
  .second .call-to-action {
    left: calc(50% - 74px);
    bottom: 102px;
  }
  .button {
    font-size: 16px;
    padding: 9px 18px;
  }
  /* .bg-image .item img {
    object-fit: contain;
  } */
  .step .text-block {
    padding-left: 60px;
    margin-left: 10%;
  }
  .bottom-slider {
    width: 100%;
  }
  .bottom-navs {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .card.quoteblock img {
    width: 100%;
  }
  .keywordsBlock {
    flex-direction: column;
  }
  .keywordsBlock-info {
    width: 100%;
  }
  .second .card {
    flex-direction: column;
    padding: 0;
  }
  .second .card .card-text p {
    padding: 30px;
  }
  .second .card .card-text {
    position: relative;
    padding: 0;
    width: 100%;
  }
  .step .text-block {
    padding-left: 30px;
    padding-top: 30px;
    margin-left: 0;
    margin-top: 0;
    padding-bottom: 20px;
  }
  .bottom-slider-container .step .text-block {
    padding-top: 0px;
  }
  .step .text-block h3 {
    font-size: 24px;
  }
  .step p {
    font-size: 20px;
  }
}
@media (max-width: 550px) {
  .second .nav .prev {
    left: 0;
    padding: 20px 30px;
    top: calc(50vh - 45px);
  }
  .second .nav .next {
    right: 0 !important;
    padding: 20px 30px;
    top: calc(50vh - 45px);
  }
  #round .swiper-slide:not(.swiper-slide-active) img {
    transform: scale(0.5);
  }
  .second #round .hover-block h4 {
    font-size: 20px;
  }
  .second #round .hover-block {
    font-size: 20px;
    top: calc(50% - 43px);
  }
  .second .text {
    width: 280px;
  }
  .second .text h2 {
    font-size: 24px;
  }
  .second .text p {
    font-size: 14px;
    font-weight: 700;
    line-height: 22.6px;
  }
  .second .dots {
    right: calc(50% - 90px) !important;
    bottom: 160px;
  }
  .hidden-wrapper {
    padding: 90px 30px;
  }
  .hidden-block .close-block {
    left: 30px;
  }
  .hidden-block .dots {
    right: 30px;
  }
  .hidden-block h3 {
    font-size: 28px;
  }
  .hidden-block h2,
  .hidden-block .keywordsBlock h2 {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .card-text p {
    font-size: 18px;
    line-height: 30px;
  }
  .humans-slider p {
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    margin-top: 20px;
  }
  .keywordsBlock {
    margin-top: 60px;
  }
  .keywordsBlock p {
    font-size: 18px;
    line-height: 30px;
  }
  .step .text-block {
    padding: 20px;
  }
  .step .text-block .title-container {
    flex-direction: column;
  }
  .step .text-block h3 {
    margin-bottom: 10px;
    line-height: 30px;
  }
  .step p {
    font-size: 18px;
    line-height: 30px;
  }
}
</style>
