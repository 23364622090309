<template>
  <div class="services-wrapper" id="services">
    <div class="images" @click.prevent="closeSidemenuIfOpen">
      <img
        v-for="(image, index) in BGimages"
        :key="index"
        :src="getImgUrl(image, true)"
        alt=""
        :class="{ active: activeImage === index }"
      />
    </div>
    <div :class="{ services: true, collapsed: isHiddenBlockOpen }">
      <div class="mobile-slider">
        <swiper
          class="slider"
          :slides-per-view="1"
          :speed="500"
          ref="servicesSlider"
          :draggable="true"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="(service, index) in translation.services.services"
            :key="index"
          >
            <div :class="{ item: true, active: index === activeHiddenBlock }">
              <h2
                @click="
                  (e) => {
                    openHiddenBlock(index);
                  }
                "
              >
                {{ service.title }}
              </h2>
              <ul
                @click="
                  (e) => {
                    openHiddenBlock(index);
                  }
                "
              >
                <li
                  v-for="(item, index) in typeof service.listshort !==
                  'undefined'
                    ? service.listshort
                    : service.list"
                  :key="index"
                >
                  <p>{{ item }}</p>
                </li>
              </ul>
              <a href="#" @click.prevent="showModal" class="button">{{
                service.button
              }}</a>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="desktop-services">
        <div
          :class="{ item: true, active: index === activeHiddenBlock }"
          v-for="(service, index) in translation.services.services"
          :key="index"
          @click="
            (e) => {
              openHiddenBlock(index);
            }
          "
        >
          <h2
            @click="
              (e) => {
                openHiddenBlock(index);
              }
            "
          >
            {{ service.title }}
          </h2>
          <ul>
            <li
              v-for="(item, index) in typeof service.listshort !== 'undefined'
                ? service.listshort
                : service.list"
              :key="index"
            >
              <p>{{ item }}</p>
            </li>
          </ul>
          <a href="#" class="button" @click.prevent="showModal">{{
            service.button
          }}</a>
        </div>
      </div>
    </div>
     
    <div
      :class="{ 'hidden-block': true, active: isHiddenBlockOpen }"
      ref="servicesHiddenBlock"
    >
      <!-- <div class="hidden-overflow" @click="closeHiddenBlock"></div> -->
      <div class="hidden-wrapper">
        <a href="#" class="close-block" @click.prevent="closeHiddenBlock"
          ><img class="closesign" :src="getImgUrl('close_icon_new.png')" alt="" /></a
        >
        <div class="left-part">
          <h2>
            {{ translation.services.services[activeHiddenBlock].titleSecond }}
          </h2>
          <ul>
            <li
              v-for="(item, index) in translation.services.services[
                activeHiddenBlock
              ].list"
              :key="index"
            >
              <p>{{ item }}</p>
            </li>
          </ul>
        </div>
        <div class="right-part">
          <p
            v-html="
              translation.services.services[activeHiddenBlock].info.topText
            "
          ></p>
          <img
            :src="translation.services.services[activeHiddenBlock].info.image"
            alt=""
          />
          <p
            v-html="
              translation.services.services[activeHiddenBlock].info.bottomText
            "
          ></p>
          <img
            :src="translation.services.services[activeHiddenBlock].info.image2"
            alt=""
          />
          <div class="card card-text-bottom quoteblock">
            <div class="card-text">
              <p>
                <img :src="getImgUrl('quotes.png')" alt="" /><span
                  v-html="
                    translation.services.services[activeHiddenBlock].info
                      .quoteText
                  "
                ></span>
              </p>
            </div>
          </div>
          <a href="#" @click.prevent="showModal" class="button">{{
            translation.services.services[activeHiddenBlock].button
          }}</a>
          <div class="last-block"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";
export default {
  name: "Services",
  components: {
    Swiper,
    SwiperSlide,
    // services() {
    // return this.translation.services.services;
    // },
  },
  props: {
    translation: Object,
  },
  data() {
    return {
      activeImage: 0,
      isHiddenBlockOpen: false,
      activeHiddenBlock: 0,
      BGimages: [
        "/img/services/s9.jpg",
        "/img/services/s8.jpg",
        "/img/services/s7.jpg",
        "/img/services/s6.jpg",
        "/img/services/s1.jpg",
        "/img/services/s10.jpg",
      ],
      // services: ,
    };
  },
  methods: {
    showModal(e) {
      console.log(e);
      e.stopPropagation();
      window.showModal("light");
    },
    updateSlider() {
      this.$refs.servicesSlider.swiperRef.update();
    },
    getImgUrl(pet, fix) {
      if (typeof fix !== "undefined") return pet;
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
    openHiddenBlock(index) {
      // const _t = this;
      this.activeHiddenBlock = index;
      this.isHiddenBlockOpen = true;
      this.$nextTick(() => {
        document.querySelector("#services .hidden-wrapper").scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
      window.hideFixedHeader();
    },
    closeSidemenuIfOpen() {
      if(this.isHiddenBlockOpen){
        this.closeHiddenBlock();
      }
    },
    closeHiddenBlock() {
      this.isHiddenBlockOpen = false;
      window.showFixedHeader();
    },
    onSlideChange(swiper) {
      if (swiper.activeIndex === 0) {
        this.activeImage = 0;
      } else if (swiper.activeIndex === 1) {
        this.activeImage = 1;
      } else {
        this.activeImage = 2;
      }
    },
  },
  mounted() {
    const services = document.getElementById("services");
    const _t = this;
    window.closeServicesHiddenBlock = this.closeHiddenBlock;
    window.updateServiceSlider = this.updateSlider;
    services.addEventListener("mousemove", (e) => {
      if (_t.isHiddenBlockOpen || window.innerWidth < 991) {
        return;
      }
      const interval = window.innerWidth / 6;
      if (e.clientX < interval) {
        this.activeImage = 0;
      } else if (e.clientX > interval && e.clientX < interval * 2) {
        this.activeImage = 1;
      } else if (e.clientX > interval * 2 && e.clientX < interval * 3) {
        this.activeImage = 2;
      } else if (e.clientX > interval * 3 && e.clientX < interval * 4) {
        this.activeImage = 3;
      } else if (e.clientX > interval * 4 && e.clientX < interval * 5) {
        this.activeImage = 4;
      } else {
        this.activeImage = 5;
      }
    });
  },
};
</script>

<style>
.services-wrapper .card.quoteblock .card-text {
  width: 100%;
}
.services-wrapper .hidden-block .button {
  display: inline-block;
}
.services-wrapper .hidden-block .last-block {
  height: 300px;
  width: 100%;
}
.mobile-slider {
  width: 100%;
  display: none;
}
.mobile-slider .item .button {
  margin-top: 30px;
}
.services .mobile-slider .item {
  max-width: 100%;
}
.services.collapsed {
  justify-content: flex-start;
}
.services.collapsed .desktop-services {
  flex-direction: column;
  align-self: flex-start;
  max-width: calc(100% - 1290px);
  align-items: center;
  min-width: 300px;
}
.services.collapsed .item ul {
  display: none;
}
.services.collapsed .item:hover {
  opacity: 1;
}
.services.collapsed .item.active {
  opacity: 1;
}
.services.collapsed .item {
  margin: 0;
  opacity: 0.4;
  transition: all 0.3s;
}
.services.collapsed .item .button {
  display: none;
}
.services-wrapper .hidden-block .close {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: 117px;
  left: 90px;
  cursor: pointer;
  font-size: 30px;
  color: white;
}
.services-wrapper .hidden-block h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  color: #fff;
  margin: 0;
  margin-bottom: 60px;
}
.services-wrapper .hidden-block ul {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.services-wrapper .hidden-block ul li {
  margin: 0;
  margin-bottom: 15px;
  padding-right: 15px;
  position: relative;
}
.services-wrapper .hidden-block ul li::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  top: 10px;
  left: -30px;
}
.services-wrapper .hidden-block .quoteblock p {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.services-wrapper .hidden-block .right-part .quoteblock img {
  width: 100%;
  margin: 30px 32px 30px 0;
  display: block;
}
.services-wrapper .hidden-block p {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}
.closesign {
  font-weight: 300;
  font-family: "Roboto";
}
.services-wrapper .hidden-block .left-part {
  width: 320px;
}
.services-wrapper .hidden-block .right-part {
  flex: 1;
  padding-top: 0;
  padding-left: 80px;
}
.services-wrapper .hidden-block .right-part img {
  width: 100%;
  margin: 30px 0;
}
.services-wrapper .hidden-block {
  display: flex;
  color: #fff;
}
.services-wrapper .hidden-block .hidden-wrapper {
  display: flex;
  flex-direction: row;
}
.services-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.services-wrapper .images {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  filter: brightness(0.4);
}
.services-wrapper > h3 {
  color: #fff;
  font-size: 34px;
  line-height: 49px;
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 877px;
  text-align: center;
  margin: 0;
  margin-top: 50px;
}
.services-wrapper .images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
}
.services-wrapper .hidden-block h3 {
  font-size: 30px;
}
.services-wrapper .images img.active {
  opacity: 1;
}
.services {
  z-index: 3;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}
.services .item {
  text-align: center;
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.services .item .button {
  display: inline-block;
  margin-top: 33px;
  font-size: 20px;
  line-height: 23px;
  padding: 11px 22px;
}
.services .item h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
  color: #fff;
}
.services .item p.description {
  opacity: 0.7;
  margin-bottom: 11px;
}
.services .item p {
  font-size: 23px;
  line-height: 36px;
  color: #fff;
  margin: 0;
}
.services-wrapper .hidden-block .right-part p, .services-wrapper .hidden-block .left-part *{
color: #fff;
}
.services .item ul {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.desktop-services {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 1199px) {
  .services .item p {
    font-size: 18px;
    line-height: 30px;
  }
  .services-wrapper .hidden-block .close {
    top: 50px;
    left: 90px;
  }
}
@media (max-width: 991px) {
.services .item .button {
  margin-top: 20px;
  }
  .services-wrapper > h3 {
    display: none;
  }
  .mobile-slider {
    display: block;
  }
  .desktop-services {
    display: none;
  }
  
  .services-wrapper .hidden-block .button {
    left: calc(50% - 74px);
    position: relative;
  }    
}
@media (max-width: 767px) {
  .services-wrapper .hidden-block .hidden-wrapper {
    flex-direction: column;
  }
  .services-wrapper .hidden-block .right-part {
    padding-top: 20px;
    padding-left: 0;
  }
  .services-wrapper .hidden-block .right-part .last-block {
    height: 0;
  }
}
@media (max-width: 550px) {
  .services-wrapper .hidden-block ul li::before {
    width: 7px;
    height: 7px;
    top: 13px;
    left: -20px;
  }
  .services-wrapper .hidden-block ul {
    padding-left: 20px;
  }
  .services-wrapper .hidden-block .close {
    left: 30px;
  }
}
</style>
