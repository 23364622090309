var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thrid"},[_c('h3',{staticClass:"section-description",domProps:{"innerHTML":_vm._s(_vm.translation.services.sectionDescription)}}),_c('div',{staticClass:"numbers"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"count",attrs:{"id":"value1"}},[_vm._v("0")]),_c('h3',{attrs:{"id":"value11"}},[_vm._v(_vm._s(_vm.translation.thrid.numbers[0].title))]),_c('p',[_vm._v(_vm._s(_vm.translation.thrid.numbers[0].description))])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"count",attrs:{"id":"value2"}},[_vm._v("0")]),_c('h3',{attrs:{"id":"value21"}},[_vm._v(_vm._s(_vm.translation.thrid.numbers[1].title))]),_c('p',[_vm._v(_vm._s(_vm.translation.thrid.numbers[1].description))])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"count",attrs:{"id":"value3"}},[_vm._v("0")]),_c('h3',{attrs:{"id":"value31"}},[_vm._v(_vm._s(_vm.translation.thrid.numbers[2].title))]),_c('p',[_vm._v(_vm._s(_vm.translation.thrid.numbers[2].description))])]),_c('div',{staticClass:"item"},[_c('span',{staticClass:"count",attrs:{"id":"value4"}},[_vm._v("0")]),_c('h3',{attrs:{"id":"value41"}},[_vm._v(_vm._s(_vm.translation.thrid.numbers[3].title))]),_c('p',[_vm._v(_vm._s(_vm.translation.thrid.numbers[3].description))])])]),_c('swiper',{ref:"thridSlider",staticClass:"slider",attrs:{"slides-per-view":3,"speed":300,"draggable":true,"loop":true,"breakpoints":{
      320: {
        slidesPerView: 2,
      },
      767: {
        slidesPerView: 3,
      },
    }}},_vm._l((_vm.partnersLogo),function(item,index){return _c('swiper-slide',{key:index,staticClass:"item"},[_c('a',{on:{"click":function($event){$event.stopPropagation();return (function (){ return false; }).apply(null, arguments)}}},[_c('img',{attrs:{"src":item[0],"alt":""}})]),_c('a',{on:{"click":function($event){$event.stopPropagation();return (function (){ return false; }).apply(null, arguments)}}},[_c('img',{attrs:{"src":item[1],"alt":""}})])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }