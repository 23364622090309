<template>
  <div class="reviews">
    <div
      :class="{ 'reviews-wrapper': true, 'reviews-wrapper-scroll': isMobile }"
    >
      <h2>DQBD on air</h2>
      
      <div class="item">
              <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7057677439111426048" height="270" width="504" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
            </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "swiper-vue2";
export default {
  name: "Posts",
  components: {
    // Swiper,
    // SwiperSlide,
  },
  data() {
    return {
      isMobile: false,
      isPrevBtnShowed: false,
      isNextBtnShowed: true,
    };
  },
  mounted() {
    if (window.innerWidth < 550) {
      this.isMobile = true;
    }
  },
  props: {
    translation: Object,
  },
  methods: {
    // onSlideChange(swiper) {
    //   this.activeSlide = swiper.activeIndex;
    //   if (swiper.isEnd) {
    //     this.isNextBtnShowed = false;
    //   } else {
    //     this.isNextBtnShowed = true;
    //   }
    //   if (!swiper.isBeginning) {
    //     this.isPrevBtnShowed = true;
    //   } else {
    //     this.isPrevBtnShowed = false;
    //   }
    // },
    // getImgUrl(pet) {
    //   var images = require.context("../assets/", false, /\.*$/);
    //   return images("./" + pet);
    // },
    // reviewSliderPrev() {
    //   if (typeof this.$refs.reviewsSlider !== "undefined") {
    //     this.$refs.reviewsSlider.swiperRef.slidePrev();
    //   }
    // },
    // reviewSliderNext() {
    //   if (typeof this.$refs.reviewsSlider !== "undefined") {
    //     this.$refs.reviewsSlider.swiperRef.slideNext();
    //   }
    // },
  },
};
</script>

<style>
.reviews {
  height: 100%;
  width: 100%;
  color: #fff;
  display: flex;
}
.reviews .navs {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.reviews .navs .prev {
  position: absolute;
  background: transparent;
  border: none;
  left: 60px;
  top: calc(50% - 25px);
  cursor: pointer;
}
.reviews .navs .next {
  position: absolute;
  background: transparent;
  border: none;
  right: 60px;
  top: calc(50% - 25px);
  cursor: pointer;
}
.reviews-wrapper {
  padding: 89px 70px 95px 10vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviews-wrapper .slider-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100vw - 140px);
  max-width: 875px;
  padding-left: 160px;
  margin-left: -120px;
}
/* .reviews-wrapper .slider-wrapper .slider {
  padding-left: 160px;
} */
.reviews-wrapper .item {
  position: relative;
}
.reviews-wrapper .item::before {
  content: "";
  width: 42px;
  height: 42px;
  background: url("../assets/review-quote.png");
  position: absolute;
  top: 0;
  left: -107px;
}
.reviews-wrapper h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  margin: 0;
  align-self: flex-start;
  padding-left: 0;
  margin-bottom: 40px;
}
/* .reviews-wrapper .slider {
  max-width: 875px;
} */
.reviews-wrapper .slider p {
  font-size: 26px;
  line-height: 40px;
  margin: 0;
  margin-bottom: 37px;
  max-width: 875px;
  width: 100%;
}
.reviews-wrapper .slider h5 {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 7px;
}
.reviews-wrapper .slider h6 {
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.reviews-wrapper .slider img {
  display: block;
  margin: auto 0;
  width: auto;
  max-width: 282px;
  max-height: 50px;
  margin-top: 25px;
}
.reviews-wrapper .slider {
  width: 100%;
}
@media (max-width: 1253px) {
.reviews-wrapper .slider-wrapper {
margin-left: -48px;
}
}
@media (max-width: 1199px) {
  .reviews-wrapper .item {
    padding-right: 220px;
  }
}
@media (max-width: 991px) {
  .reviews-wrapper .slider p {
    font-size: 28px;
    line-height: 36px;
  }
  .reviews-wrapper .item::before {
    left: -67px;
  }
  .reviews-wrapper .slider-wrapper {
    padding-left: 120px;
  }
}
@media (min-width: 767px) {
  .reviews-wrapper .slider img {
    margin-right: 24px;
    margin-bottom: 0;
  }
  .reviews-wrapper .slider .flexinner {
    display: block;
  }
}
@media (max-width: 767px) {
  .reviews-wrapper .item::before {
    left: 0;
    top: 0;
  }
  .reviews-wrapper .item {
    padding-top: 60px;
    padding-right: 0;
  }
  .reviews-wrapper .slider-wrapper {
    padding-left: 0;
    margin-left: 0;
  }
  .reviews-wrapper h2 {
    padding-left: 0;
  }
  .reviews .navs{
    top: 195px;
    left: 0;
    height: auto;
	display:block;
	z-index: 9;
  }
  .reviews .navs .prev {
    right: -176px;
    left: 42px;
  }
  .reviews .navs img {
	width: 23px;
  }
  .reviews .navs .next {
    right: 20px;
  }
  .reviews-wrapper {
    padding: 70px 40px;
  }
}
@media (max-width: 550px) {
  .reviews-wrapper h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .reviews-wrapper .slider p {
    font-size: 22px;
    line-height: 28px;
  }
  .reviews-wrapper .slider img {
    margin-top: 20px;
  }
  .reviews-wrapper {
    padding: 20px;
    padding-top: 83px;
    padding-left: 44px;
  }
  .reviews-wrapper .slider-wrapper {
    width: calc(100vw - 60px);
    margin-left:0;
  }
  .navs {
    display: none;
  }
}
.reviews-wrapper * {
    color: #fff;
}
</style>
