<template>
  <div class="team-wrapper">
    <div class="team-info">
      <div class="team-info-wrapper">
        <h2>{{ translation.team.title }}</h2>
        <p>{{ translation.team.description }}</p>
        <a
          :href="link.link"
          class="link"
          target="_blank"
          v-for="(link, index) in translation.team.links"
          :key="index"
          ><i class="fa-solid fa-file-pdf"></i> {{ link.text }}</a
        >
        <a
          href="#"
          @click.prevent="showModal"
          target="_blank"
          class="button black-button"
          >{{ translation.team.button }}</a
        >
      </div>
    </div>
    <div class="team-bg">
      <swiper
          class="slider"
          :slides-per-view="1"
          :speed="500"
		  :loop="true" 
          ref="teamsSlider"
          :draggable="true"
          :autoplay="true"
        >
          <swiper-slide
            v-for="(item, index) in translation.team.peoples"
            :key="index"
          >		  
            <img :src="item.img" alt="" />
          </swiper-slide>
        </swiper>
    </div>
    <div v-if="!1" class="team-slider" style="display: none;"> 
      <div class="team-slider-wrapper">
        <swiper
          class="slider"
          :slides-per-view="1"
          :speed="500"
          ref="teamSlider"
          :autoplay="{ delay: 3000, disableOnInteraction: false }"
          :draggable="true"
          @slideChange="onTeamSliderChange"
        >
          <swiper-slide v-for="(slide, index) in slides" :key="index">
            <div class="image-group">
              <div class="item" v-if="slide[0]">
                <img :src="slide[0].img" alt="" />
                <div class="descr-block">
                  <p>{{ slide[0].name }}</p>
                  <span>{{ slide[0].position }}</span>
                </div>
              </div>

              <div class="item" v-if="slide[1]">
                <img :src="slide[1].img" alt="" />
                <div class="descr-block">
                  <p>{{ slide[1].name }}</p>
                  <span>{{ slide[1].position }}</span>
                </div>
              </div>
              <div class="item" v-if="slide[2]">
                <img :src="slide[2].img" alt="" />
                <div class="descr-block">
                  <p>{{ slide[2].name }}</p>
                  <span>{{ slide[2].position }}</span>
                </div>
              </div>
              <div class="item" v-if="slide[3]">
                <img :src="slide[3].img" alt="" />
                <div class="descr-block">
                  <p>{{ slide[3].name }}</p>
                  <span>{{ slide[3].position }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="navs">
        <button class="prev" @click="teamSliderPrev" v-show="isPrevBtnShowed">
          <img :src="getImgUrl('arrow-left-black.png')" alt="" />
        </button>
        <button class="next" @click="teamSliderNext" v-show="isNextBtnShowed">
          <img :src="getImgUrl('arrow-right-black.png')" alt="" />
        </button>
      </div>
      <div class="dots">
        <div
          class="dot-wrapper"
          v-for="item in 3"
          :key="item"
          @click="
            (e) => {
              slidersGoTo(item);
            }
          "
        >
          <div :class="{ dot: true, active: activeSldie === item - 1 }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper-vue2";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay])

export default {
  name: "Team",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isTeamSliderShown: false,
      isPrevBtnShowed: false,
      isNextBtnShowed: true,
      activeSldie: 0
    };
  },
  props: {
    translation: Object,
  },
  computed: {
    slides() {
      const calculateSlides = [];
      let tempIndex = 0;
      for (
        let index = 0;
        index < this.translation.team.peoples.length;
        index++
      ) {
        if (calculateSlides[tempIndex]) {
          calculateSlides[tempIndex].push(this.translation.team.peoples[index]);
        } else {
          calculateSlides[tempIndex] = new Array(
            this.translation.team.peoples[index]
          );
        }
        if ((index + 1) % 4 === 0 && index + 1 !== 0) {
          tempIndex++;
        }
      }
      console.log("calculateSlides", calculateSlides);
      return calculateSlides;
    },
  },
  mounted() {
    const _t = this;
    window.closeTeamSlider = function () {
      // _t.closeTeamSlider();
    };
    console.log(this.slides);
    window.addSlide = function () {/*      
      _t.$refs.teamSlider.swiperRef.prependSlide(
        `<div class="swiper-slide"><div class="item mobile-item">
              <h2>Team</h2>
              <p>
                Unsere Stärke? Jeder und jede einzelne. Wir sind ausdauernd,
                zielstrebig, verspielt, wissbegierig und offen. Sie wollen
                wissen, ob das so stimmt? Lernen Sie uns kennen!
              </p>
              <a href="#" class="button black-button" onclick="window.showModal('hidden')">Get to know Us</a>
            </div></div>`
      );
      _t.$refs.teamSlider.swiperRef.slideTo(0, 0);
      _t.$refs.teamAvatarSlider.swiperRef.slideTo(0, 0);
	  */
    };
	let _to = 0
	let _go = () => {
		console.log('....',_to)
		clearTimeout(_to)
		this.$refs.teamsSlider.swiperRef.slideNext()
		setTimeout(_go,2500)
	}
	_go()
  },
  methods: {
    showModal() {
      window.showModal("hidden");
    },
    slidersGoTo(index) {
      this.$refs.teamSlider.swiperRef.slideTo(index, 500);	  
    },
    openTeamSlider() {
      this.isTeamSliderShown = true;
    },
    closeTeamSlider() {
      this.$refs.teamSlider.swiperRef.slideTo(0, 400);
      this.activeSldie = 0;
      this.isTeamSliderShown = false;
    },
    onTeamSliderChange(swiper) {
      this.activeSlide = swiper.activeIndex;
      if (swiper.isEnd) {
        this.isNextBtnShowed = false;
      } else {
        this.isNextBtnShowed = true;
      }
      if (!swiper.isBeginning) {
        this.isPrevBtnShowed = true;
      } else {
        this.isPrevBtnShowed = false;
      }
      this.activeSldie = swiper.activeIndex;
    },

    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.*$/);
      return images("./" + pet);
    },
    teamSliderPrev() {
      if (typeof this.$refs.teamSlider !== "undefined") {
        const index = this.$refs.teamSlider.swiperRef.activeIndex;
        if (index - 1 >= 0) {
          this.$refs.teamSlider.swiperRef.slideTo(index - 1, 500);
        }
      }
    },
    teamSliderNext() {
      if (typeof this.$refs.teamSlider !== "undefined") {
        const index = this.$refs.teamSlider.swiperRef.activeIndex;
        if (index + 1 <= this.$refs.teamSlider.swiperRef.slides.length) {
          this.$refs.teamSlider.swiperRef.slideTo(index + 1, 500);
        }
      }
    },
  },
};
</script>

<style>
.team-slider h2 {
  width: calc(100% - 80px);
}
.team-slider p {
  padding: 0 40px;
}
.team-slider .dots {
  bottom: 35px;
  left: calc(50% - 45px);
  right: unset;
}
.team-slider .dots .dot-wrapper {
  padding: 9px;
}
.team-slider .dots .dot {
  background-color: #000;
}
.team-slider .dots .dot:last-of-type {
  margin-right: 0;
}
.open-team-slider {
  position: absolute;
  left: -500px;
  width: 500px;
  height: 100%;
  background: transparent;
  z-index: 5;
}
.team-avatar-wrapper .swiper-wrapper {
  align-items: flex-end;
}
.team-avatar-wrapper .swiper-slide-active {
  transform: scale(1);
}
.team-avatar-wrapper .swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.5);
}
.team-avatar-wrapper .swiper-slide.swiper-slide-next {
  opacity: 0;
}
.team-avatar-wrapper .swiper-slide {
  transform-origin: bottom right;
  transition: all 0.3s;
}

.team-avatar-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateX(-480px);
  height: 100%;
}
.team-slider .navs .prev {
  background-color: transparent;
  border: none;
  position: absolute;
  left: 20px;
  top: calc(50% - 25px);
  z-index: 5;
  cursor: pointer;
}
.team-slider .navs .next {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 20px;
  top: calc(50% - 25px);
  z-index: 5;
  cursor: pointer;
}
.team-slider-wrapper {
  width: calc(100% - 110px);
  padding: 0;
  overflow: hidden;
  max-width: 520px;
}
.team-wrapper {
  display: flex;
  overflow: hidden;
}
.team-info {
  width: 35%;
  height: 100vh;
  background: white;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
}
.team-info-wrapper {
  padding: 0px 30px;
  max-width: 388px;
  margin: auto;
}
.team-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  width: 65%;
}
.team-bg .slider {
  height: 100%;
}
.team-bg img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.team-slider {
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  min-width: 380px;
  flex-direction: column;
}
.team-slider.shown {
  transform: translateX(0%);
}
.team-slider-wrapper .image-group {
  display: flex;
  flex-wrap: wrap;
}
.team-slider-wrapper .image-group .descr-block * {
	color:#fff;
}
.team-slider-wrapper .image-group .descr-block {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 20px);
  background: rgb(0 0 0 / 40%);
  color: #fff;
  padding: 10px;
  padding-bottom: 20px;
  transform: translateY(100%);
  transition: all 0.5s;
}
.team-wrapper .descr-block p {
  margin: 0;
  padding: 0;
  line-height: 24px;
  margin-bottom: 10px;
}
.team-slider-wrapper .image-group .item:hover .descr-block {
  transform: translateY(0);
}
.team-slider-wrapper .image-group .item {
  margin-bottom: 33px;
  width: calc(50% - 33px);
  padding: 0;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}
.team-slider-wrapper .image-group .item img {
  width: 100%;
  object-fit: cover;
}
.team-slider .item {
  padding: 0 60px;
  max-width: 380px;
  margin: auto;
}
.team-slider .item a {
  display: block;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #000000;
}
.team-info-wrapper .link:hover {
  text-decoration: underline;
}
.team-info-wrapper .link {
  display: block;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #000000;
}
.team-info-wrapper .link i {
  margin-right: 10px;
}
.team-wrapper h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  margin: 0;
  max-width: 520px;
}
.team-wrapper p {
  font-size: 22px;
  line-height: 36px;
  margin: 0;
  margin-bottom: 36px;
  max-width: 520px;
}
.team-wrapper .button.black-button {
  font-size: 20px;
  margin-top: 16px;
  line-height: 23px;
  background-color: #000;
  color: #fff;
  padding: 11px 22px;
  display: inline-block;
}
@media (min-width: 991px) {  
	.team-info-wrapper h2 {
		margin-bottom:20px;
	}
}
@media (max-width: 1199px) {
  .team-slider {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .team-slider-wrapper .image-group {
    height: 100%;
    position: relative;
  }
  .team-slider-wrapper .image-group .item {
    height: calc(50% - 33px);
  }
  .team-bg {
    height: 600px;
    position: relative;
    width: 100%;
  }
  .team-bg img {
    padding-left: 0;
    width: 100%;
  }
  .team-info {
    padding-bottom: 40px;
  }
  .team-slider h2 {
    max-width: unset;
    padding-left: 30px;
    padding-right: 30px;
  }
  .team-slider p {
    max-width: unset;
    padding-left: 30px;
    padding-right: 30px;
  }
  .team-info {
width: 100%;
height: auto;
  }
  .team-info-wrapper{
     padding:83px 31px 0 40px;
  }
  .team-wrapper {
    flex-direction: column;
    height: 100%;
  }
  .team-slider {
    transform: translateX(0);
    width: 100%;
    height: auto;
    background: white;
    align-items: flex-start;
	position:initial;
  }
  .team-slider-wrapper {
    background-color: #fff;
    height: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .team-slider .navs .prev {
    bottom: 20px;
    top: unset;
  }
  .team-slider .navs .next {
    bottom: 20px;
    top: unset;
  }
  .team-avatar-wrapper {
    transform: scale(0.5);
    transform-origin: bottom right;
  }
  .team-slider-wrapper .slider {
    width: 100%;
    height: 100%;
  }
  .team-slider .dots {
    bottom: 0;
  }
  .team-wrapper h2 {
    font-size: 30px;
  }
}
@media (max-width: 550px) {
  .team-slider {
    height: auto;
    padding-top: 23px;
    padding-bottom: 80px;
  }
  .team-slider-wrapper {
    width: 100%;
    height: auto;
  }
  .team-bg img {
    padding-left: 0%;
    width: 100%;
  }
  .team-slider {
    min-width: unset;
  }
  .team-wrapper h2 {
    font-size: 30px;
  }
  .team-wrapper p {
    font-size: 18px;
    line-height: 28px;
  }
  .team-slider .item a {
    font-size: 22px;
  }
  .team-slider .item {
    padding: 0 30px;
  }
}
</style>
