<template>
  <div class="thrid">
  <h3 class="section-description" v-html="translation.services.sectionDescription">
    </h3>
    <div class="numbers">
      <div class="item">
        <span class="count" id="value1">0</span>
        <h3 id="value11">{{ translation.thrid.numbers[0].title }}</h3>
        <p>{{ translation.thrid.numbers[0].description }}</p>
      </div>
      <div class="item">
        <span class="count" id="value2">0</span>
        <h3 id="value21">{{ translation.thrid.numbers[1].title }}</h3>
        <p>{{ translation.thrid.numbers[1].description }}</p>
      </div>
      <div class="item">
        <span class="count" id="value3">0</span>
        <h3 id="value31">{{ translation.thrid.numbers[2].title }}</h3>
        <p>{{ translation.thrid.numbers[2].description }}</p>
      </div>
      <div class="item">
        <span class="count" id="value4">0</span>
        <h3 id="value41">{{ translation.thrid.numbers[3].title }}</h3>
        <p>{{ translation.thrid.numbers[3].description }}</p>
      </div>
    </div>
    <swiper
      class="slider"
      :slides-per-view="3"
      :speed="300"
      ref="thridSlider"
      :draggable="true"
      :loop="true"
      :breakpoints="{
        320: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 3,
        },
      }"
    >
      <swiper-slide
        class="item"
        v-for="(item, index) in partnersLogo"
        :key="index"
      >
        <a  @click.stop="()=>false"><img :src="item[0]" alt="" /></a>
        <a  @click.stop="()=>false"><img :src="item[1]" alt="" /></a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
/*eslint-disable*/
import { Swiper, SwiperSlide } from "swiper-vue2";
export default {
  name: "Thrid",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    translation: Object,
  },
  data() {
    return {
      autoplayTimeout: null,
    };
  },
  computed: {
    partnersLogo() {
      let partners = [];
      //(item, index, array) in this.translation.thrid.partners
      for (
        var index = 0;
        index < this.translation.thrid.partners.length;
        index++
      ) {
        const item = this.translation.thrid.partners[index];
        if (index % 2 === 0) {
          partners.push([item, this.translation.thrid.partners[index + 1]]);
        }
      }
      return partners;
    },
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./" + pet);
    },
    animateValue(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    createSliderAutoplay() {
      const _t = this;
      this.autoplayTimeout = setTimeout(() => {
        _t.$refs.thridSlider.swiperRef.slideNext();
        _t.createSliderAutoplay();
      }, 5000);
    },
    deleteSliderAutoplay() {
      clearTimeout(this.autoplayTimeout);
    },
  },
  mounted() {
    const _t = this;
    window.thridGoAutoplay = function () {
      _t.createSliderAutoplay();
    };
    window.thridStopAutoplay = function () {
      _t.deleteSliderAutoplay();
    };
    window.startCalculateNumbers = function () {
      _t.animateValue(document.getElementById("value1"), 0, _t.translation.thrid.numbers[0].number, 1000);
      _t.animateValue(document.getElementById("value2"), 0, _t.translation.thrid.numbers[1].number, 1500);
      _t.animateValue(document.getElementById("value3"), 0, _t.translation.thrid.numbers[2].number, 2500);
      _t.animateValue(document.getElementById("value4"), 0, _t.translation.thrid.numbers[3].number, 3500);
    };
  },
};
</script>

<style>
.section-description{
    font-size: 34px;
    line-height: 49px;
    width: 100%;
    text-align: center;
    margin: 0;
    margin-top: 50px;
}
.thrid .slider .item {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .7;
}
.thrid .slider .item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 200px;
  max-height: 78px;
}
.thrid .slider .item a {
  margin-right: 2.8vw;
  margin-left: 2.8vw;
  opacity: 1;
}
.thrid .slider {
  margin-top: 190px;
  overflow: hidden;
}
.thrid {
  padding: 89px 146px 110px 10vw;
  /* overflow: scroll;
  height: 100%; */
}
.thrid h2 {
  font-weight: 600;
  font-size: 40px;
  margin: 0;
  line-height: 47px;
}
.thrid .numbers {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}
.numbers .item {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
  max-width: 330px;
}
.numbers .item > * {
  margin: 0;
}
.numbers .item span {
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 82px;
}
.numbers .item h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 20px;
}
.numbers .item p {
  font-size: 22px;
  line-height: 26px;
}
.swiper-wrapper {
  align-items: center;
}
@media (max-width: 1199px) {
  .numbers .item p {
    font-size: 18px;
    line-height: 24px;
  }
  .thrid {
    padding: 60px;
  }
  .thrid .numbers {
    margin-top: 60px;
  }
  .thrid .slider {
    margin-top: 100px;
  }
  .thrid h2 {
    font-size: 30px;
    line-height: 36px;
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .numbers .item span {
    font-size: 60px;
  }
  .numbers .item h3 {
    font-size: 26px;
  }
  .numbers .item p {
    font-size: 16px;
  }
  .numbers .item {
    padding: 0px 10px;
  }
}
@media (max-width: 767px) {
  .thrid .numbers {
    flex-wrap: wrap;
    justify-content: center;
  }
  .numbers .item {
    margin-bottom: 50px;
  }
}
@media (max-width: 550px) {
  .thrid .slider .item {
    display: flex;
    flex-direction: column;
  }
  .thrid .slider .item a {
    margin-bottom: 20px;
  }
  .thrid {
    padding: 30px;
    padding-bottom: 60px;
  }
  .thrid .slider {
    margin-top: 60px;
  }
}
@media (min-width: 767px) {
	.thrid{
		display: flex;
		flex-direction: column;
		padding-top: 0;
		height: 100%;
		justify-content: space-evenly;
		padding-bottom: 0;		
	}
	.thrid .section-description,
	.thrid .slider.swiper-container,
	.thrid .numbers
	{
		    margin-top: 0;
	}
}
</style>
