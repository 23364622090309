/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import "fullpage.js/vendors/scrolloverflow.js"; // Optional. When using scrollOverflow:true
import "fullpage.js/dist/fullpage.min.css"; // Optional. When using scrollOverflow:true
import VueFullPage from "vue-fullpage.js";

import router from './router'

Vue.use(VueFullPage);
Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
