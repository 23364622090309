var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews"},[_c('div',{class:{ 'reviews-wrapper': true, 'reviews-wrapper-scroll': _vm.isMobile }},[_c('h2',[_vm._v(_vm._s(_vm.translation.testimonials.title))]),_c('div',{staticClass:"slider-wrapper"},[_c('swiper',{ref:"reviewsSlider",staticClass:"slider",attrs:{"slides-per-view":1,"space-between":160,"speed":500,"draggable":true,"breakpoints":{
            320: {
              spaceBetween: 20,
            },
            767: {
              spaceBetween: 160,
            },
          }},on:{"slideChange":_vm.onSlideChange}},_vm._l((_vm.translation.testimonials.reviews),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"item"},[(item.text && item.text.length !== 0)?_c('p',{domProps:{"innerHTML":_vm._s(item.text)}}):_vm._e(),(item.author && item.author.length !== 0)?_c('h5',[_vm._v(" "+_vm._s(item.author)+" ")]):_vm._e(),(item.company && item.company.length !== 0)?_c('h6',[_vm._v(" "+_vm._s(item.company)+" ")]):_vm._e(),(item.img && item.img.length !== 0)?_c('img',{attrs:{"src":item.img,"alt":""}}):_vm._e()])])}),1),_c('div',{staticClass:"navs"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPrevBtnShowed),expression:"isPrevBtnShowed"}],staticClass:"prev",on:{"click":_vm.reviewSliderPrev}},[_c('img',{attrs:{"src":_vm.getImgUrl('arrow-left.png'),"alt":""}})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNextBtnShowed),expression:"isNextBtnShowed"}],staticClass:"next",on:{"click":_vm.reviewSliderNext}},[_c('img',{attrs:{"src":_vm.getImgUrl('arrow-right.png'),"alt":""}})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }